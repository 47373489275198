<template>
  <div class="yinye">
    <div>&nbsp;</div>
    <div class="card">
      <div>营业执照</div>
      <img src="../assets/img/business2023.png">
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      
    }
  },
}
</script>
<style lang="less" scoped>
.yinye{
  width: 100%;
  height: 100%;
  background-image: url(../assets/img/yinyeback.png);
  background-size: cover;
  /* 将背景图片等比缩放填满整个容器 */
  background-repeat: no-repeat;
  background-position:center;
  .card{width: 800px; height: 648px;margin: 60px auto 0;background: #FFFFFF;border-radius: 12px;
    >div{font-size: 28px;font-family: Source Han Sans SC-Medium, Source Han Sans SC;font-weight: 500;color: #2D394B;padding: 24px 0; text-align: center; border-bottom: 1px solid #EBEBEB;}
    img{width: 680px; height: 480px; margin: 40px 60px 0;}
  }
}
</style>