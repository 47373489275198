<template>
  <div class="gonetwork0">
    <el-container>
      <!-- 页头 -->
      <el-header>
        <div class="maintitle">
          <img src="../../assets/img/gonetworkicon.png" @click="gonetwork0" />
          <div class="active" @click="gonetwork0">首页</div>
          <div @click="gonetwork1">产品介绍</div>
          <div @click="gonetwork2">功能介绍</div>
          <div @click="gonetwork3">下载</div>
        </div>
      </el-header>
      <!-- 主体内容 -->
      <el-main>
        <div class="gonetworkmain"></div>
        <div class="gonetworkval">
          <div class="title">新版特性</div>
          <!-- <div class="valinfo1">
            <div class="valtext">
              <img class="img1" src="../../assets/img/gonetwork2_icon1.png" />
              <div class="tal">在线赚钱</div>
              <div class="tel">
                <div></div>
                <div>做任务轻松赚出零花钱</div>
              </div>
              <div class="tel">
                <div></div>
                <div>奖励金额可提现至支付宝、银行卡</div>
              </div>
            </div>
            <img src="../../assets/img/gonetwork0_1.png" />
          </div> -->
          <div class="valinfo2">
            <img src="../../assets/img/gonetwork0_2.png" />
            <div class="valtext">
              <img class="img1" src="../../assets/img/gonetwork2_icon5.png" />
              <div class="tal">极速上机</div>
              <div class="tel">
                <div></div>
                <div>
                  与“8圈鹰眼”实时联动，无需刷卡或扫码，一键确认即可快速上机
                </div>
              </div>
            </div>
          </div>
          <div class="valinfo3">
            <div class="valtext">
              <img class="img1" src="../../assets/img/gonetwork2_icon3.png" />
              <div class="tal">找工作</div>
              <div class="tel">
                <div></div>
                <div>同城推送海量电竞行业热门职位招聘信息，轻松帮您找工作</div>
              </div>
            </div>
            <img src="../../assets/img/gonetwork0_3.png" />
          </div>
        </div>
      </el-main>
      <!-- 底部 -->
      <el-footer>
        <div class="fotter">
          <div class="fotterdiv">
            <div class="yuedong">
              <img src="../../assets/img/gonetworkfooter.png" />
              <div class="text1">
                © Copyright 2008 yedone.com all rights reserved.
                渝网文[2013]0887-006号
              </div>
              <div class="text2">
                经营许可证：渝B2-20190234&nbsp;&nbsp;|&nbsp;&nbsp;<span @click="zhizhao">营业执照</span><br />渝ICP备09014244号-16&nbsp;&nbsp;|&nbsp;&nbsp;<span @click="gongyue">平台公约</span>
              </div>
              <div class="text3">
                <img src="../../assets/img/guohui.png" />
                <div class="text3tel" @click="beian">
                  渝公网安备 50010802002169号
                </div>
              </div>
            </div>
            <div class="bor">
              <div class="bor1"></div>
              <div class="bor2"></div>
            </div>
            <div class="contactUs">
              <div class="women">联系我们</div>
              <div class="phone">
                <div class="dianhua1">
                  <div>客服电话（24h）</div>
                  <div>400-680-8811</div>
                </div>
                <div class="dianhua2">
                  <div>商务电话（工作日09:00-18:00)</div>
                  <div>023-68636622</div>
                </div>
              </div>
              <div class="phone2">
                <div class="dianhua1">
                  <div>客服QQ（24h）</div>
                  <div>4006808811</div>
                </div>
                <div class="dianhua2">
                  <div>公司地址</div>
                  <div>重庆市南岸区南城大道199号正联大厦3楼</div>
                </div>
              </div>
            </div>
            <div class="attention">
              <div class="women">关注我们</div>
              <div class="erweima">
                <div class="fuwu">
                  <img src="../../assets/img/qushangwang.png" />
                  <div>去上网APP微信服务号</div>
                </div>
                <div class="gongzong">
                  <img src="../../assets/img/baquan.jpg" />
                  <div>8圈计费微信公众号</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </el-footer>
    </el-container>
  </div>
</template>
<script>
export default {
  name: "gonetwork0",
  data() {
    return {};
  },
  methods: {
    //跳转首页
    gonetwork0() {
      this.$router.push("/gonetwork0");
    },
    //跳转产品介绍
    gonetwork1() {
      this.$router.push("/gonetwork1");
    },
    //跳转功能介绍
    gonetwork2() {
      this.$router.push("/gonetwork2");
    },
    //跳转下载
    gonetwork3() {
      this.$router.push("/gonetwork3");
    },
    //跳转备案
    beian() {
      window.open(
        "http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=50010802002169"
      );
    },
    //跳转营业执照
    zhizhao(){
      let routeData = this.$router.resolve('/yinye');
      window.open(routeData.href, '_blank');
    },
    gongyue(){
      window.open("http://8quan.com/pingtaigongyue.html")
    }
  },
  mounted() {
    if (
      /Android|webOS|iPhone|iPod|BlackBerry|Harmony/i.test(navigator.userAgent)
    ) {
      //移动设备打开的网页，缩放页面尺寸
      /*
  		var clientWidth = document.body.clientWidth;
  		var rate = clientWidth / 1920;
  		*/
      var rate = 0.1;
      var meta = document.getElementsByTagName("meta");
      meta["viewport"].setAttribute(
        "content",
        "width=device-width,initial-scale=" + rate
      );
    }
  },
};
</script>
<style lang="less" scoped>
// 页头样式
.el-header {
  width: 100%;
  min-width: 1200px;
  height: 80px !important;
  box-sizing: border-box;
  padding: 16px 0 0;
  margin: 0 auto;
  .maintitle {
    display: flex;
    align-items: center;
    width: 1200px;
    margin: 0 auto;
    > img {
      width: 140px;
      height: 40px;
      margin-right: 50px;
      &:hover {
        cursor: pointer;
      }
    }
    > div {
      width: 72px;
      height: 27px;
      text-align: center;
      font-size: 18px;
      font-family: Source Han Sans SC;
      font-weight: 500;
      line-height: 27px;
      color: #666666;
      margin-left: 80px;
      &:hover {
        cursor: pointer;
      }
    }
    .active {
      opacity: 1;
      position: relative;
      color: #ff6c00;
      &::after {
        content: "";
        position: absolute;
        width: 30px;
        height: 4px;
        background: #ff6c00;
        opacity: 1;
        bottom: -10px;
        left: 50%;
        transform: translate(-50%, 50%);
      }
    }
  }
}
.el-main {
  width: 100%;
  min-width: 1200px;
  padding: 0;
  margin: 0 auto;
  background: #f4f5f9;
  .gonetworkmain {
    margin: 0 auto;
    height: 460px;
    background-image: url(../../assets/img/gonetwork0back.png);
    background-size: cover;
    /* 将背景图片等比缩放填满整个容器 */
    background-repeat: no-repeat;
    background-position: center;
  }
  .gonetworkval {
    width: 1200px;
    margin: 0 auto;
    padding: 60px 0 80px;
    .title {
      width: 310px;
      height: 54px;
      font-size: 36px;
      font-family: Source Han Sans SC-Bold, Source Han Sans SC;
      font-weight: bold;
      color: #2d394b;
    }
    .valinfo1 {
      width: 1200px;
      padding: 20px;
      margin-top: 30px;
      background: #ffffff;
      border-radius: 16px;
      box-sizing: border-box;
      display: flex;
      .valtext {
        margin: 140px 0 0 60px;
        .img1 {
          width: 100px;
          height: 100px;
        }
        .tal {
          width: 356px;
          height: 59px;
          font-size: 40px;
          font-family: Source Han Sans SC-Medium, Source Han Sans SC;
          font-weight: 500;
          color: #2d394b;
          margin: 20px 0 30px;
        }
        .tel {
          display: flex;
          width: 502px;
          margin-top: 20px;
          & > div:first-child {
            width: 14px;
            height: 14px;
            min-width: 14px;
            min-height: 14px;
            background: linear-gradient(318deg, #ff8000 0%, #ff9100 100%);
            border-radius: 50%;
            margin: 11px 8px 0 0;
          }
          & > div:last-child {
            font-size: 24px;
            line-height: 36px;
            font-family: Source Han Sans SC-Regular, Source Han Sans SC;
            font-weight: 400;
            color: #626778;
          }
        }
      }
      > img {
        width: 570px;
        height: 710px;
        margin-left: 28px;
      }
    }
    .valinfo2 {
      width: 1200px;
      padding: 20px;
      margin-top: 30px;
      background: #ffffff;
      border-radius: 16px;
      box-sizing: border-box;
      display: flex;
      > img {
        width: 570px;
        height: 710px;
      }
      .valtext {
        margin: 160px 0 0 28px;
        .img1 {
          width: 100px;
          height: 100px;
        }
        .tal {
          width: 356px;
          height: 59px;
          font-size: 40px;
          font-family: Source Han Sans SC-Medium, Source Han Sans SC;
          font-weight: 500;
          color: #2d394b;
          margin: 20px 0 30px;
        }
        .tel {
          display: flex;
          width: 502px;
          margin-top: 20px;
          & > div:first-child {
            width: 14px;
            height: 14px;
            min-width: 14px;
            min-height: 14px;
            background: linear-gradient(318deg, #ff8000 0%, #ff9100 100%);
            border-radius: 50%;
            margin: 11px 8px 0 0;
          }
          & > div:last-child {
            font-size: 24px;
            line-height: 36px;
            font-family: Source Han Sans SC-Regular, Source Han Sans SC;
            font-weight: 400;
            color: #626778;
          }
        }
      }
    }
    .valinfo3 {
      width: 1200px;
      padding: 20px;
      margin-top: 30px;
      background: #ffffff;
      border-radius: 16px;
      box-sizing: border-box;
      display: flex;
      .valtext {
        margin: 140px 0 0 60px;
        .img1 {
          width: 100px;
          height: 100px;
        }
        .tal {
          width: 356px;
          height: 59px;
          font-size: 40px;
          font-family: Source Han Sans SC-Medium, Source Han Sans SC;
          font-weight: 500;
          color: #2d394b;
          margin: 20px 0 30px;
        }
        .tel {
          display: flex;
          width: 502px;
          margin-top: 20px;
          & > div:first-child {
            width: 14px;
            height: 14px;
            min-width: 14px;
            min-height: 14px;
            background: linear-gradient(318deg, #ff8000 0%, #ff9100 100%);
            border-radius: 50%;
            margin: 11px 8px 0 0;
          }
          & > div:last-child {
            font-size: 24px;
            line-height: 36px;
            font-family: Source Han Sans SC-Regular, Source Han Sans SC;
            font-weight: 400;
            color: #626778;
          }
        }
      }
      > img {
        width: 570px;
        height: 710px;
        margin-left: 28px;
      }
    }
  }
}
//底部
.el-footer {
  width: 100%;
  min-width: 1200px;
  margin: 0 auto;
  padding: 0;
  .fotter {
    height: 338px;
    background: #0b192b;
    padding-top: 51px;
    box-sizing: border-box;
    .fotterdiv {
      width: 1200px;
      height: 225px;
      margin: 0 auto;
      display: flex;
      .yuedong {
        width: 273px;
        height: 225px;
        font-size: 14px;
        font-family: Source Han Sans SC;
        font-weight: 400;
        line-height: 21px;
        color: #626778;
        > img {
          width: 140px;
          height: 40px;
        }
        .text1 {
          margin-top: 47px;
        }
        .text2 {
          margin-top: 14px;
          span:hover{cursor: pointer;color: #ffffff;}
        }
        .text3 {
          margin-top: 14px;
          display: flex;
          justify-items: center;
          img {
            margin-right: 10px;
          }
          .text3tel {
            &:hover {
              cursor: pointer;
              color: #ffffff;
            }
          }
        }
      }
      .bor {
        width: 1px;
        height: 225px;
        margin-left: 36px;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        .bor1 {
          flex: 1;
        }
        .bor2 {
          height: 120px;
          border: 1px solid #1b2c43;
        }
      }
      .contactUs {
        width: 475px;
        height: 218px;
        margin-left: 50px;
        margin-top: 9px;
        font-size: 20px;
        font-family: Source Han Sans SC;
        font-weight: 500;
        line-height: 18px;
        color: #ffffff;
        .women {
          width: 80px;
          height: 29px;
        }
        .phone,
        .phone2 {
          margin-top: 57px;
          display: flex;
          .dianhua1,
          .dianhua2 {
            div:nth-child(1) {
              height: 20px;
              font-size: 14px;
              font-weight: 400;
              color: #626778;
            }
            div:nth-child(2) {
              height: 22px;
              font-size: 18px;
              font-family: DIN;
              color: #ffffff;
              margin-top: 8px;
            }
          }
          .dianhua1 {
            width: 124px;
          }
          .dianhua2 {
            margin-left: 52px;
          }
        }
        .phone2 {
          margin-top: 30px;
          .dianhua2 div:nth-child(2) {
            font-family: Source Han Sans SC;
            font-size: 16px;
          }
        }
      }
      .attention {
        width: 298px;
        height: 218px;
        margin-left: 67px;
        margin-top: 9px;
        font-size: 20px;
        font-family: Source Han Sans SC;
        font-weight: 500;
        line-height: 18px;
        color: #ffffff;
        .women {
          width: 80px;
          height: 29px;
          margin-left: 10px;
        }
        .erweima {
          display: flex;
          margin-top: 51px;
          .fuwu,
          .gongzong {
            font-size: 14px;
            font-weight: 400;
            color: #626778;
            text-align: center;
            > img {
              width: 110px;
              height: 110px;
            }
            > div {
              margin-top: 8px;
            }
          }
          .gongzong {
            margin-left: 38px;
          }
        }
      }
    }
  }
}
</style>
