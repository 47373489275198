<template>
  <div class="feature">
    <el-container>
      <!-- 页头 -->
      <el-header>
        <div class="title">
          <div class="image" @click="shouye">
            <img src="../assets/img/title.png" />
          </div>
          <div class="titleBtn" id="p1">
            <div @click="shouye">首页</div>
            <div @click="product">产品介绍</div>
            <div @click="feature" class="active">功能介绍</div>
            <div @click="download">下载</div>
          </div>
          <div class="denglu" @click="logins">登录网吧后台</div>
        </div>
      </el-header>
      <!-- 主体内容 -->
      <div class="featuremain"></div>
      <!-- 滑动吸顶组件 -->
      <sticky-header ref="sticky_">
        <!-- 次级导航栏 -->
        <div class="daohang">
          <div class="dhtitle">
            <div :class="id == 1 ? 'checked' : ''" @click="onclick(1)">
              计费管理
            </div>
            <div :class="id == 2 ? 'checked' : ''" @click="onclick(2)">
              商品管理
            </div>
            <div :class="id == 3 ? 'checked' : ''" @click="onclick(3)">
              场所管理
            </div>
            <div :class="id == 4 ? 'checked' : ''" @click="onclick(4)">
              营销活动
            </div>
            <div :class="id == 5 ? 'checked' : ''" @click="onclick(5)">
              经营数据
            </div>
            <div :class="id == 6 ? 'checked' : ''" @click="onclick(6)">
              在线支付
            </div>
            <div :class="id == 7 ? 'checked' : ''" @click="onclick(7)">
              应用市场
            </div>
          </div>
        </div>
      </sticky-header>
      <el-main>
        <!-- 计费管理页面内容 -->
        <div class="dhinfo1" v-if="id == 1">
          <div class="tatle1">
            <img src="../assets/img/jf1.png" />
            <div class="info1">
              <div class="info1tal">上/下机方式多样化</div>
              <div class="info1tel">
                <div class="info1text">
                  <img src="../assets/img/jficon.png" />
                  <div>多种上机方式</div>
                </div>
                <div class="info1text2">
                  支持吧台、客户机、8圈机器人、8圈鹰眼激活上网卡 <br />
                  支持二代身份证刷卡、去上网APP扫码多种上机方式
                </div>
              </div>
              <div class="info1tel">
                <div class="info1text">
                  <img src="../assets/img/jficon.png" />
                  <div>多种下机渠道</div>
                </div>
                <div class="info1text2">
                  支持吧台、客户机、8圈机器人、去上网APP结账下机
                </div>
              </div>
            </div>
          </div>
          <div class="tatle2">
            <div class="info2">
              <div class="info2tal">收银快捷操作</div>
              <div class="info2tel">
                <img src="../assets/img/jficon.png" />
                <div>回车键快速加钱、快速下机</div>
              </div>
              <div class="info2tel">
                <img src="../assets/img/jficon.png" />
                <div>搜索客户机/姓名/证件号/拼音快速加钱</div>
              </div>
              <div class="info2tel">
                <img src="../assets/img/jficon.png" />
                <div>鼠标拖动在线客户机快速换机</div>
              </div>
              <div class="info2tel">
                <img src="../assets/img/jficon.png" />
                <div>快捷功能区，自主配置</div>
              </div>
              <div class="info2tel">
                <img src="../assets/img/jficon.png" />
                <div>充送活动，自主停用</div>
              </div>
            </div>
            <img src="../assets/img/jf2.png" />
          </div>
          <div class="tatle3">
            <img src="../assets/img/jf3.png" />
            <div class="info3">
              <div class="info3tal">网费充值管理</div>
              <div class="info3tel">
                <div class="info3text">
                  <img src="../assets/img/jficon.png" />
                  <div>网费充值</div>
                </div>
                <div class="info3text2">
                  支持吧台、客户机、8圈机器人、去上网APP多种渠道充值
                  <br />支持使用现金、二维码、扫码枪多种收款方式
                </div>
              </div>
              <div class="info3tel">
                <div class="info3text">
                  <img src="../assets/img/jficon.png" />
                  <div>充值修正</div>
                </div>
                <div class="info3text2">充值金额有误，支持多次修正</div>
              </div>
            </div>
          </div>
          <div class="tatle4">
            <div class="info4">
              <div class="info4tal">包时/套餐购买</div>
              <div class="info4tel">
                <img src="../assets/img/jficon.png" />
                <div>支持自主购买、预订包时套餐</div>
              </div>
              <div class="info4tel">
                <img src="../assets/img/jficon.png" />
                <div>提供吧台、客户机、8圈机器人多种销售渠道</div>
              </div>
              <div class="info4tel">
                <img src="../assets/img/jficon.png" />
                <div>支持账户余额购买</div>
              </div>
              <div class="info4tel">
                <img src="../assets/img/jficon.png" />
                <div>可限制账户余额不可购买（强收现金）</div>
              </div>
            </div>
            <img src="../assets/img/jf4.png" />
          </div>
          <div class="tatle5">
            <img src="../assets/img/jf5.png" />
            <div class="info5">
              <div class="info5tal">收银交接班</div>
              <div class="info5tel">
                <div class="info5text">
                  <img src="../assets/img/jficon.png" />
                  <div>独立交班</div>
                </div>
                <div class="info5text2">计费商品班次收入数据分开统计</div>
              </div>
              <div class="info5tel">
                <div class="info5text">
                  <img src="../assets/img/jficon.png" />
                  <div>联合交班</div>
                </div>
                <div class="info5text2">
                  商品收银端先交班，计费收银端再交班，实现账务一体化
                </div>
              </div>
              <div class="info5tel">
                <div class="info5text">
                  <img src="../assets/img/jficon.png" />
                  <div>交班数据实时同步</div>
                </div>
                <div class="info5text2">
                  交班数据实时同步至管理后台，并推送至“掌上8圈APP”
                </div>
              </div>
            </div>
          </div>
          <div class="tatle6">
            <div class="info6">
              <div class="info6tal">停电模式</div>
              <div class="info6tel">
                <img src="../assets/img/jficon.png" />
                <div>支持异常停电时停止计费，实现停电期间不计费</div>
              </div>
            </div>
            <img src="../assets/img/jf6.png" />
          </div>
          <div class="tatle7">
            <img src="../assets/img/jf7.png" />
            <div class="info7">
              <div class="info7tal">打印设置</div>
              <div class="info7tel">
                <img src="../assets/img/jficon.png" />
                <div>支持上机、下机、交班、加钱小票打印</div>
              </div>
              <div class="info7tel">
                <img src="../assets/img/jficon.png" />
                <div>打印内容可自主编辑</div>
              </div>
            </div>
          </div>
        </div>
        <!-- 商品管理页面内容 -->
        <div class="dhinfo2" v-else-if="id == 2">
          <div class="tatle1">
            <img src="../assets/img/sp1.png" />
            <div class="info1">
              <div class="info1tal">商品管理</div>
              <div class="info1tel">
                <img src="../assets/img/jficon.png" />
                <div>仓库/吧台进出货</div>
              </div>
              <div class="info1tel">
                <img src="../assets/img/jficon.png" />
                <div>吧台上下架商品</div>
              </div>
              <div class="info1tel">
                <img src="../assets/img/jficon.png" />
                <div>手机点货、手机盘存</div>
              </div>
            </div>
          </div>
          <div class="tatle2">
            <div class="info2">
              <div class="info2tal">库存预警</div>
              <div class="info2tel">
                <img src="../assets/img/jficon.png" />
                <div>吧台、仓库商品数量不足时，及时推送预警信息至吧台</div>
              </div>
            </div>
            <img src="../assets/img/sp2.png" />
          </div>
          <div class="tatle3">
            <img src="../assets/img/sp3.png" />
            <div class="info3">
              <div class="info3tal">独特套餐</div>
              <div class="info3tel">
                <img src="../assets/img/jficon.png" />
                <div>计费和商品自由组合，增长低收入时段收益</div>
              </div>
            </div>
          </div>
          <div class="tatle4">
            <div class="info4">
              <div class="info4tal">商品销售</div>
              <div class="info4tel">
                <div class="info4text">
                  <img src="../assets/img/jficon.png" />
                  <div>吧台销售</div>
                </div>
                <div class="info4text2">支持现金、二维码、扫码枪收款</div>
              </div>
              <div class="info4tel">
                <div class="info4text">
                  <img src="../assets/img/jficon.png" />
                  <div>客户机自助购买</div>
                </div>
                <div class="info4text2">
                  支持会员充值的网费购买（会员现金账户）<br />
                  支持会员充值赠送的商品金额购买（会员商品账户）<br />
                  支持使用现金、二维码扫码购买
                </div>
              </div>
            </div>
            <img src="../assets/img/sp4.png" />
          </div>
          <div class="tatle5">
            <img src="../assets/img/sp5.png" />
            <div class="info5">
              <div class="info5tal">商品退货</div>
              <div class="info5tel">
                <img src="../assets/img/jficon.png" />
                <div>可对近24小时的商品订单进行退货退款</div>
              </div>
              <div class="info5tel">
                <img src="../assets/img/jficon.png" />
                <div>在线支付商品订单退货产生的退款支持退回原支付账户</div>
              </div>
            </div>
          </div>
        </div>
        <!-- 场所管理页面内容 -->
        <div class="dhinfo3" v-else-if="id == 3">
          <div class="tatle1">
            <img src="../assets/img/cs1.png" />
            <div class="info1">
              <div class="info1tal">客户机管理</div>
              <div class="info1tel">
                <img src="../assets/img/jficon.png" />
                <div>客户机可进行区域分配管理，包间分配管理</div>
              </div>
              <div class="info1tel">
                <img src="../assets/img/jficon.png" />
                <div>网民上机剩余时间不足，客户机支持多种提醒方式</div>
              </div>
            </div>
          </div>
          <div class="tatle2">
            <div class="info2">
              <div class="info2tal">费率管理</div>
              <div class="info2tel">
                <img src="../assets/img/jficon.png" />
                <div>
                  可针对不同区域、不同会员等级、不同时段设置不同的费率方案
                </div>
              </div>
            </div>
            <img src="../assets/img/cs2.png" />
          </div>
          <div class="tatle3">
            <img src="../assets/img/cs3.png" />
            <div class="info3">
              <div class="info3tal">员工管理</div>
              <div class="info3tel">
                <img src="../assets/img/jficon.png" />
                <div>
                  收银端、管理后台、掌上8圈APP可以分模块分等级的进行权限管理授权
                </div>
              </div>
              <div class="info3tel">
                <img src="../assets/img/jficon.png" />
                <div>员工上网可限制上网时段</div>
              </div>
            </div>
          </div>
          <div class="tatle4">
            <div class="info4">
              <div class="info4tal">会员管理</div>
              <div class="info4tel">
                <img src="../assets/img/jficon.png" />
                <div>支持修改会员信息、退卡、转卡、删卡等管理</div>
              </div>
              <div class="info4tel">
                <img src="../assets/img/jficon.png" />
                <div>提供新增会员、流失会员数据统计分析</div>
              </div>
            </div>
            <img src="../assets/img/cs4.png" />
          </div>
          <div class="tatle5">
            <img src="../assets/img/cs5.png" />
            <div class="info5">
              <div class="info5tal">结算管理</div>
              <div class="info5tel">
                <img src="../assets/img/jficon.png" />
                <div>在线支付支持小微、个体工商户、企业开户</div>
              </div>
              <div class="info5tel">
                <img src="../assets/img/jficon.png" />
                <div>
                  在线支付收入支持提现到个人账户、法人对私账户、对公账户
                </div>
              </div>
              <div class="info5tel">
                <img src="../assets/img/jficon.png" />
                <div>提供支付明细、提现明细记录</div>
              </div>
            </div>
          </div>
          <div class="tatle6">
            <div class="info6">
              <div class="info6tal">联合管理</div>
              <div class="info6tel">
                <div class="info6text">
                  <img src="../assets/img/jficon.png" />
                  <div>连锁</div>
                </div>
                <div class="info6text2">支持账户、会员、经营统一化管理</div>
              </div>
              <div class="info6tel">
                <div class="info6text">
                  <img src="../assets/img/jficon.png" />
                  <div>联盟</div>
                </div>
                <div class="info6text2">
                  统一管理会员账户，可以实现会员共享，联盟管理者还可享受分成
                </div>
              </div>
              <div class="info6tel">
                <div class="info6text">
                  <img src="../assets/img/jficon.png" />
                  <div>弱联盟</div>
                </div>
                <div class="info6text2">
                  可以实现会员共享，但场所经营管理互不影响
                </div>
              </div>
              <div class="info6tel">
                <div class="info6text">
                  <img src="../assets/img/jficon.png" />
                  <div>监控</div>
                </div>
                <div class="info6text2">
                  对市场费率进行最低设防，避免恶性竞争
                </div>
              </div>
            </div>
            <img src="../assets/img/cs6.png" />
          </div>
        </div>
        <!-- 营销活动页面内容 -->
        <div class="dhinfo4" v-else-if="id == 4">
          <div class="tatle1">
            <img src="../assets/img/yx1.png" />
            <div class="info1">
              <div class="info1tal">支持多种费率方案<br />生效顺序灵活运用</div>
              <div class="info1tel">
                <img src="../assets/img/jficon.png" />
                <div>会员日</div>
              </div>
              <div class="info1tel">
                <img src="../assets/img/jficon.png" />
                <div>网民生日</div>
              </div>
              <div class="info1tel">
                <img src="../assets/img/jficon.png" />
                <div>女性会员</div>
              </div>
              <div class="info1tel">
                <img src="../assets/img/jficon.png" />
                <div>日期范围</div>
              </div>
            </div>
          </div>
          <div class="tatle2">
            <div class="info2">
              <div class="info2tal">提供多种营销方案<br />网吧经营小帮手</div>
              <div class="bor">
                <div class="left">
                  <div class="info2tel">
                    <img src="../assets/img/jficon.png" />
                    <div>包时套餐</div>
                  </div>
                  <div class="info2tel">
                    <img src="../assets/img/jficon.png" />
                    <div>充送活动</div>
                  </div>
                  <div class="info2tel">
                    <img src="../assets/img/jficon.png" />
                    <div>优惠券</div>
                  </div>
                  <div class="info2tel">
                    <img src="../assets/img/jficon.png" />
                    <div>派钱</div>
                  </div>
                </div>
                <div class="right">
                  <div class="info2tel">
                    <img src="../assets/img/jficon.png" />
                    <div>签到返利</div>
                  </div>
                  <div class="info2tel">
                    <img src="../assets/img/jficon.png" />
                    <div>会员储值上机返利</div>
                  </div>
                  <div class="info2tel">
                    <img src="../assets/img/jficon.png" />
                    <div>会员老带新</div>
                  </div>
                </div>
              </div>
            </div>
            <img src="../assets/img/yx2.png" />
          </div>
        </div>
        <!-- 经营数据页面内容 -->
        <div class="dhinfo5" v-else-if="id == 5">
          <div class="tatle1">
            <img src="../assets/img/jy1.png" />
            <div class="info1">
              <div class="info1tal">提供全面的经营数据统计</div>
              <div class="info1tel">
                <img src="../assets/img/jficon.png" />
                <div>经营流水明细</div>
              </div>
              <div class="info1tel">
                <img src="../assets/img/jficon.png" />
                <div>收支、借还款记录</div>
              </div>
              <div class="info1tel">
                <img src="../assets/img/jficon.png" />
                <div>交班记录</div>
              </div>
              <div class="info1tel">
                <img src="../assets/img/jficon.png" />
                <div>在线支付明细</div>
              </div>
              <div class="info1tel">
                <img src="../assets/img/jficon.png" />
                <div>商品销售订单明细</div>
              </div>
              <div class="info1tel">
                <img src="../assets/img/jficon.png" />
                <div>商品消耗量统计</div>
              </div>
            </div>
          </div>
          <div class="tatle2">
            <div class="info2">
              <div class="info2tal">提供直观的营业数据分析</div>
              <div class="info2tel">
                <img src="../assets/img/jficon.png" />
                <div>上座率分析</div>
              </div>
              <div class="info2tel">
                <img src="../assets/img/jficon.png" />
                <div>收入分析</div>
              </div>
              <div class="info2tel">
                <img src="../assets/img/jficon.png" />
                <div>充值分析</div>
              </div>
              <div class="info2tel">
                <img src="../assets/img/jficon.png" />
                <div>会员分析</div>
              </div>
              <div class="info2tel">
                <img src="../assets/img/jficon.png" />
                <div>消费分析</div>
              </div>
            </div>
            <img src="../assets/img/jy2.png" />
          </div>
        </div>
        <!-- 在线支付页面内容 -->
        <div class="dhinfo6" v-else-if="id == 6">
          <div class="tatle1">
            <img src="../assets/img/zx1.png" />
            <div class="info1">
              <div class="info1tal">支付方式</div>
              <div class="info1tel">
                <img src="../assets/img/jficon.png" />
                <div>支付宝</div>
              </div>
              <div class="info1tel">
                <img src="../assets/img/jficon.png" />
                <div>微信</div>
              </div>
              <div class="info1tel">
                <img src="../assets/img/jficon.png" />
                <div>云闪付</div>
              </div>
              <div class="info1tel">
                <img src="../assets/img/jficon.png" />
                <div>扫码枪收款</div>
              </div>
            </div>
          </div>
          <div class="tatle2">
            <div class="info2">
              <div class="info2tal">支付清算</div>
              <div class="info2tel">
                <img src="../assets/img/jficon.png" />
                <div>网吧支持T+1清算</div>
              </div>
            </div>
            <img src="../assets/img/zx2.png" />
          </div>
          <div class="tatle3">
            <img src="../assets/img/zx3.png" />
            <div class="info3">
              <div class="info3tal">收入提现</div>
              <div class="info3tel">
                <img src="../assets/img/jficon.png" />
                <div>管理后台提现</div>
              </div>
              <div class="info3tel">
                <img src="../assets/img/jficon.png" />
                <div>掌上8圈APP提现</div>
              </div>
            </div>
          </div>
          <div class="tatle4">
            <div class="info4">
              <div class="info4tal">支付场景</div>
              <div
                class="info4tel"
                :class="index == 0 ? 'info4che' : ''"
                @click="setActiveItem(0)"
              >
                <img src="../assets/img/jficon.png" />
                <div>场所收银台</div>
              </div>
              <div
                class="info4tel"
                :class="index == 1 ? 'info4che' : ''"
                @click="setActiveItem(1)"
              >
                <img src="../assets/img/jficon.png" />
                <div>8圈机器人</div>
              </div>
              <div
                class="info4tel"
                :class="index == 2 ? 'info4che' : ''"
                @click="setActiveItem(2)"
              >
                <img src="../assets/img/jficon.png" />
                <div>客户机</div>
              </div>
              <div
                class="info4tel"
                :class="index == 3 ? 'info4che' : ''"
                @click="setActiveItem(3)"
              >
                <img src="../assets/img/jficon.png" />
                <div>去上网APP</div>
              </div>
            </div>
            <div class="lunbo">
              <el-carousel
                height="640px"
                direction="vertical"
                indicator-position="none"
                :autoplay="true"
                ref="carousel"
                :interval="2000"
                @change="onlunbo"
              >
                <el-carousel-item name="0">
                  <img src="../assets/img/zx4.png" />
                </el-carousel-item>
                <el-carousel-item name="1">
                  <img src="../assets/img/zx5.png" />
                </el-carousel-item>
                <el-carousel-item name="2">
                  <img src="../assets/img/zx6.png" />
                </el-carousel-item>
                <el-carousel-item name="3">
                  <img src="../assets/img/zx7.png" />
                </el-carousel-item>
              </el-carousel>
            </div>
          </div>
        </div>
        <!-- 应用市场页面内容 -->
        <div class="dhinfo7" v-else-if="id == 7">
          <div class="val">
            基于互联网开放和分享的理念，打造8圈开放平台，开发者可通过平台提供更多优秀的应用供网吧选择使用。
          </div>
          <div class="image">
            <img src="../assets/img/apply.png" />
          </div>
        </div>
      </el-main>
      <!-- 底部 -->
      <footerComponents></footerComponents>
    </el-container>
  </div>
</template>
<script>
import footerComponents from "../components/footer.vue";
import stickyHeader from "../components/sticky.vue";
export default {
  name: "feature",
  components: { footerComponents, stickyHeader },
  data() {
    return {
      id: "1", //显示的次级导航
      index: "0", //显示第几个轮播图
    };
  },
  mounted() {
    if (
      /Android|webOS|iPhone|iPod|BlackBerry|Harmony/i.test(navigator.userAgent)
    ) {
      //移动设备打开的网页，缩放页面尺寸
      /*
  		var clientWidth = document.body.clientWidth;
  		var rate = clientWidth / 1920;
  		*/
      var rate = 0.1;
      var meta = document.getElementsByTagName("meta");
      meta["viewport"].setAttribute(
        "content",
        "width=device-width,initial-scale=" + rate
      );
    }
  },
  methods: {
    //跳转首页
    shouye() {
      this.$router.push("/");
    },
    //点击转跳产品介绍
    product() {
      this.$router.push("/product");
    },
    //跳转功能介绍页面
    feature() {
      this.$router.push("/feature");
    },
    //跳转下载页面
    download() {
      this.$router.push("/download");
    },
    //登录网吧后台
    logins() {
      window.open("http://b.8quan.com");
    },
    //点击切换子导航
    onclick(id) {
      this.id = id;

      //点击回到顶部
      let top = document.documentElement.scrollTop || document.body.scrollTop;
      // 实现滚动效果
      const timeTop = setInterval(() => {
        document.body.scrollTop =
          document.documentElement.scrollTop =
          top -=
            50;
        if (top <= 0) {
          clearInterval(timeTop);
        }
      }, 10);
    },
    //点击切换轮播图
    setActiveItem(index) {
      this.$refs.carousel.setActiveItem(index);
      this.index = index;
    },
    //轮播图切换时触发
    onlunbo(e) {
      console.log(e);
      this.index = e;
    },
  },
};
</script>
<style lang="less" scoped>
// 页头样式
.el-header {
  width: 100%;
  min-width: 1320px;
  padding: 0;
  margin: 0 auto;
  height: 80px !important;
  line-height: 80px;
  .title {
    display: flex;
    align-items: center;
    width: 1200px;
    margin: 0 auto;
    .image {
      min-width: 286px;
      height: 48px;
      margin-right: 2px;
      img {
        width: 286px;
        height: 48px;
      }
      &:hover {
        cursor: pointer;
      }
    }
    .titleBtn {
      display: flex;
      align-items: center;
      height: 80px;
      box-sizing: border-box;
      > div {
        margin-left: 80px;
        min-width: 36px;
        height: 27px;
        font-size: 18px;
        font-family: Source Han Sans SC;
        font-weight: 500;
        line-height: 27px;
        color: #666666;
        text-align: center;
      }
      .active {
        position: relative;
        color: #3586f1;
      }
      .active::after {
        position: absolute;
        bottom: -10px;
        content: "";
        width: 30px;
        height: 4px;
        background: #3586f1;
        left: 50%;
        transform: translate(-50%);
      }
      :hover {
        cursor: pointer;
      }
    }
    .denglu {
      width: 120px;
      height: 40px;
      background: #3586f1;
      border-radius: 8px;
      font-size: 14px;
      font-family: Source Han Sans SC;
      font-weight: 500;
      line-height: 40px;
      color: #ffffff;
      text-align: center;
      margin-left: 256px;
      &:hover {
        cursor: pointer;
      }
    }
  }
}
//顶部背景图
.featuremain {
  margin: 0 auto;
  width: 100%;
  min-width: 1320px;
  height: 300px;
  background-image: url(../assets/img/featureback.png);
  background-size: cover;
  /* 将背景图片等比缩放填满整个容器 */
  background-repeat: no-repeat;
  background-position: center;
}
//次级导航栏
.daohang {
  width: 100%;
  min-width: 1320px;
  height: 78px;
  margin: 0 auto;
  background: #f4f5f9;
  .dhtitle {
    width: 920px;
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
    padding-top: 24px;
    > div {
      width: 80px;
      height: 29px;
      font-size: 20px;
      font-family: Source Han Sans SC-Regular, Source Han Sans SC;
      font-weight: 400;
      color: #626778;
      &:hover {
        cursor: pointer;
      }
    }
    //选中
    .checked {
      position: relative;
      color: #3586f1;
      &::after {
        content: "";
        position: absolute;
        width: 80px;
        height: 4px;
        bottom: -25px;
        left: 0;
        background: #3586f1;
      }
    }
  }
}
// 主体样式
.el-main {
  width: 100%;
  min-width: 1320px;
  margin: 0 auto;
  padding: 0;

  //次级导航-计费管理页面内容
  .dhinfo1 {
    width: 1320px;
    margin: 0 auto;
    padding: 120px 0 54px;
    .tatle1 {
      display: flex;
      > img {
        width: 669px;
        height: 380px;
        margin-left: 60px;
      }
      .info1 {
        margin: 65px 0 0 125px;
        .info1tal {
          width: 406px;
          height: 47px;
          font-size: 32px;
          font-family: Source Han Sans SC-Bold, Source Han Sans SC;
          font-weight: bold;
          color: #161e2a;
        }
        .info1tel {
          margin-top: 30px;
          .info1text {
            display: flex;
            align-items: center;
            height: 29px;
            font-size: 20px;
            font-family: Source Han Sans SC-Medium, Source Han Sans SC;
            font-weight: 500;
            color: #2d394b;
            > img {
              width: 16px;
              height: 14px;
              margin-right: 12px;
            }
          }
          .info1text2 {
            margin: 10px 0 0 28px;
            font-size: 14px;
            line-height: 28px;
            font-family: Source Han Sans SC-Normal, Source Han Sans SC;
            font-weight: 400;
            color: #818697;
          }
        }
      }
    }
    .tatle2 {
      display: flex;
      margin-top: 70px;
      .info2 {
        margin-left: 60px;
        .info2tal {
          margin-top: 92px;
          margin-bottom: 30px;
          width: 406px;
          height: 47px;
          font-size: 32px;
          font-family: Source Han Sans SC-Bold, Source Han Sans SC;
          font-weight: bold;
          color: #161e2a;
        }
        .info2tel {
          display: flex;
          align-items: center;
          margin-top: 16px;
          font-size: 20px;
          font-family: Source Han Sans SC-Regular, Source Han Sans SC;
          font-weight: 400;
          color: #2d394b;
          > img {
            width: 16px;
            height: 14px;
            margin-right: 12px;
          }
        }
      }
      > img {
        width: 799px;
        height: 509px;
        margin-left: 55px;
      }
    }
    .tatle3 {
      display: flex;
      > img {
        width: 798px;
        height: 480px;
      }
      .info3 {
        margin: 96px 0 0 56px;
        .info3tal {
          width: 406px;
          height: 47px;
          font-size: 32px;
          font-family: Source Han Sans SC-Bold, Source Han Sans SC;
          font-weight: bold;
          color: #161e2a;
        }
        .info3tel {
          margin-top: 30px;
          .info3text {
            display: flex;
            align-items: center;
            height: 29px;
            font-size: 20px;
            font-family: Source Han Sans SC-Medium, Source Han Sans SC;
            font-weight: 500;
            color: #2d394b;
            > img {
              width: 16px;
              height: 14px;
              margin-right: 12px;
            }
          }
          .info3text2 {
            margin: 10px 0 0 28px;
            font-size: 14px;
            line-height: 28px;
            font-family: Source Han Sans SC-Normal, Source Han Sans SC;
            font-weight: 400;
            color: #818697;
          }
        }
      }
    }
    .tatle4 {
      display: flex;
      .info4 {
        margin-left: 60px;
        .info4tal {
          margin-top: 100px;
          margin-bottom: 30px;
          width: 443px;
          height: 47px;
          font-size: 32px;
          font-family: Source Han Sans SC-Bold, Source Han Sans SC;
          font-weight: bold;
          color: #161e2a;
        }
        .info4tel {
          display: flex;
          align-items: center;
          margin-top: 16px;
          font-size: 20px;
          font-family: Source Han Sans SC-Regular, Source Han Sans SC;
          font-weight: 400;
          color: #2d394b;
          > img {
            width: 16px;
            height: 14px;
            margin-right: 12px;
          }
        }
      }
      > img {
        width: 719px;
        height: 479px;
        margin-left: 98px;
      }
    }
    .tatle5 {
      display: flex;
      > img {
        width: 862px;
        height: 524px;
      }
      .info5 {
        margin: 71px 0 0 -8px;
        .info5tal {
          width: 406px;
          height: 47px;
          font-size: 32px;
          font-family: Source Han Sans SC-Bold, Source Han Sans SC;
          font-weight: bold;
          color: #161e2a;
        }
        .info5tel {
          margin-top: 30px;
          .info5text {
            display: flex;
            align-items: center;
            height: 29px;
            font-size: 20px;
            font-family: Source Han Sans SC-Medium, Source Han Sans SC;
            font-weight: 500;
            color: #2d394b;
            > img {
              width: 16px;
              height: 14px;
              margin-right: 12px;
            }
          }
          .info5text2 {
            margin: 10px 0 0 28px;
            font-size: 14px;
            line-height: 28px;
            font-family: Source Han Sans SC-Normal, Source Han Sans SC;
            font-weight: 400;
            color: #818697;
          }
        }
      }
    }
    .tatle6 {
      display: flex;
      .info6 {
        margin-left: 60px;
        .info6tal {
          margin-top: 166px;
          margin-bottom: 30px;
          width: 457px;
          height: 47px;
          font-size: 32px;
          font-family: Source Han Sans SC-Bold, Source Han Sans SC;
          font-weight: bold;
          color: #161e2a;
        }
        .info6tel {
          width: 457px;
          display: flex;
          align-items: center;
          margin-top: 16px;
          font-size: 20px;
          font-family: Source Han Sans SC-Regular, Source Han Sans SC;
          font-weight: 400;
          color: #2d394b;
          > img {
            width: 16px;
            height: 14px;
            margin-right: 12px;
          }
        }
      }
      > img {
        width: 799px;
        height: 509px;
        margin-left: 4px;
      }
    }
    .tatle7 {
      display: flex;
      > img {
        width: 799px;
        height: 509px;
      }
      .info7 {
        margin-left: 55px;
        .info7tal {
          margin-top: 156px;
          margin-bottom: 30px;
          width: 406px;
          height: 47px;
          font-size: 32px;
          font-family: Source Han Sans SC-Bold, Source Han Sans SC;
          font-weight: bold;
          color: #161e2a;
        }
        .info7tel {
          display: flex;
          align-items: center;
          margin-top: 16px;
          font-size: 20px;
          font-family: Source Han Sans SC-Regular, Source Han Sans SC;
          font-weight: 400;
          color: #2d394b;
          > img {
            width: 16px;
            height: 14px;
            margin-right: 12px;
          }
        }
      }
    }
  }
  //次级导航-商品管理页面内容
  .dhinfo2 {
    width: 1320px;
    margin: 0 auto;
    padding: 70px 0 51px;
    .tatle1 {
      display: flex;
      > img {
        width: 760px;
        height: 570px;
      }
      .info1 {
        margin: 127px 0 0 94px;
        .info1tal {
          width: 406px;
          height: 47px;
          font-size: 32px;
          font-family: Source Han Sans SC-Bold, Source Han Sans SC;
          font-weight: bold;
          color: #161e2a;
          margin-bottom: 30px;
        }
        .info1tel {
          display: flex;
          align-items: center;
          margin-top: 16px;
          font-size: 20px;
          font-family: Source Han Sans SC-Regular, Source Han Sans SC;
          font-weight: 400;
          color: #2d394b;
          > img {
            width: 16px;
            height: 14px;
            margin-right: 12px;
          }
        }
      }
    }
    .tatle2 {
      display: flex;
      .info2 {
        margin-left: 60px;
        .info2tal {
          margin-top: 155px;
          margin-bottom: 30px;
          width: 406px;
          height: 47px;
          font-size: 32px;
          font-family: Source Han Sans SC-Bold, Source Han Sans SC;
          font-weight: bold;
          color: #161e2a;
        }
        .info2tel {
          display: flex;
          margin-top: 16px;
          font-size: 20px;
          font-family: Source Han Sans SC-Regular, Source Han Sans SC;
          font-weight: 400;
          color: #2d394b;
          > img {
            width: 16px;
            height: 14px;
            margin-right: 12px;
            margin-top: 8px;
          }
        }
      }
      > img {
        width: 760px;
        height: 570px;
        margin-left: 94px;
      }
    }
    .tatle3 {
      display: flex;
      > img {
        width: 799px;
        height: 509px;
      }
      .info3 {
        margin: 142px 0 0 53px;
        .info3tal {
          width: 406px;
          height: 47px;
          font-size: 32px;
          font-family: Source Han Sans SC-Bold, Source Han Sans SC;
          font-weight: bold;
          color: #161e2a;
          margin-bottom: 30px;
        }
        .info3tel {
          display: flex;
          align-items: center;
          margin-top: 16px;
          font-size: 20px;
          font-family: Source Han Sans SC-Regular, Source Han Sans SC;
          font-weight: 400;
          color: #2d394b;
          > img {
            width: 16px;
            height: 14px;
            margin-right: 12px;
          }
        }
      }
    }
    .tatle4 {
      display: flex;
      .info4 {
        margin: 152px 0 0 60px;
        .info4tal {
          width: 406px;
          height: 47px;
          font-size: 32px;
          font-family: Source Han Sans SC-Bold, Source Han Sans SC;
          font-weight: bold;
          color: #161e2a;
          margin-bottom: 30px;
        }
        .info4tel {
          margin-top: 30px;
          .info4text {
            display: flex;
            align-items: center;
            width: 310px;
            height: 29px;
            font-size: 20px;
            font-family: Source Han Sans SC-Medium, Source Han Sans SC;
            font-weight: 500;
            color: #2d394b;
            > img {
              width: 16px;
              height: 14px;
              margin-right: 12px;
            }
          }
          .info4text2 {
            margin-left: 28px;
            margin-top: 10px;
            font-size: 14px;
            font-family: Source Han Sans SC-Normal, Source Han Sans SC;
            font-weight: 400;
            color: #818697;
            line-height: 28px;
          }
        }
      }
      > img {
        width: 859px;
        height: 618px;
        margin-left: -5px;
      }
    }
    .tatle5 {
      display: flex;
      > img {
        width: 760px;
        height: 570px;
      }
      .info5 {
        margin: 109px 0 0 94px;
        .info5tal {
          width: 406px;
          height: 47px;
          font-size: 32px;
          font-family: Source Han Sans SC-Bold, Source Han Sans SC;
          font-weight: bold;
          color: #161e2a;
          margin-bottom: 30px;
        }
        .info5tel {
          display: flex;
          width: 406px;
          margin-top: 16px;
          font-size: 20px;
          font-family: Source Han Sans SC-Regular, Source Han Sans SC;
          font-weight: 400;
          color: #2d394b;
          > img {
            width: 16px;
            height: 14px;
            margin-right: 12px;
            margin-top: 8px;
          }
        }
      }
    }
  }
  //次级导航-场所管理页面内容
  .dhinfo3 {
    width: 1320px;
    margin: 0 auto;
    padding: 70px 0 54px;
    .tatle1 {
      display: flex;
      > img {
        width: 800px;
        height: 560px;
      }
      .info1 {
        margin: 127px 0 0 44px;
        .info1tal {
          width: 416px;
          height: 47px;
          font-size: 32px;
          font-family: Source Han Sans SC-Bold, Source Han Sans SC;
          font-weight: bold;
          color: #161e2a;
          margin-bottom: 30px;
        }
        .info1tel {
          display: flex;
          width: 416px;
          margin-top: 20px;
          font-size: 20px;
          font-family: Source Han Sans SC-Regular, Source Han Sans SC;
          font-weight: 400;
          color: #2d394b;
          > img {
            width: 16px;
            height: 14px;
            margin-right: 12px;
            margin-top: 8px;
          }
        }
      }
    }
    .tatle2 {
      display: flex;
      .info2 {
        margin: 131px 0 0 60px;
        .info2tal {
          width: 406px;
          height: 47px;
          font-size: 32px;
          font-family: Source Han Sans SC-Bold, Source Han Sans SC;
          font-weight: bold;
          color: #161e2a;
          margin-bottom: 30px;
        }
        .info2tel {
          display: flex;
          width: 396px;
          margin-top: 20px;
          font-size: 20px;
          font-family: Source Han Sans SC-Regular, Source Han Sans SC;
          font-weight: 400;
          color: #2d394b;
          > img {
            width: 16px;
            height: 14px;
            margin-right: 12px;
            margin-top: 8px;
          }
        }
      }
      > img {
        width: 800px;
        height: 560px;
        margin-left: 22px;
      }
    }
    .tatle3 {
      display: flex;
      > img {
        width: 832px;
        height: 534px;
      }
      .info3 {
        margin: 138px 0 0 12px;
        .info3tal {
          width: 416px;
          height: 47px;
          font-size: 32px;
          font-family: Source Han Sans SC-Bold, Source Han Sans SC;
          font-weight: bold;
          color: #161e2a;
          margin-bottom: 30px;
        }
        .info3tel {
          display: flex;
          width: 416px;
          margin-top: 20px;
          font-size: 20px;
          font-family: Source Han Sans SC-Regular, Source Han Sans SC;
          font-weight: 400;
          color: #2d394b;
          > img {
            width: 16px;
            height: 14px;
            margin-right: 12px;
            margin-top: 8px;
          }
        }
      }
    }
    .tatle4 {
      display: flex;
      .info4 {
        margin: 131px 0 0 60px;
        .info4tal {
          width: 406px;
          height: 47px;
          font-size: 32px;
          font-family: Source Han Sans SC-Bold, Source Han Sans SC;
          font-weight: bold;
          color: #161e2a;
          margin-bottom: 30px;
        }
        .info4tel {
          display: flex;
          width: 396px;
          margin-top: 20px;
          font-size: 20px;
          font-family: Source Han Sans SC-Regular, Source Han Sans SC;
          font-weight: 400;
          color: #2d394b;
          > img {
            width: 16px;
            height: 14px;
            margin-right: 12px;
            margin-top: 8px;
          }
        }
      }
      > img {
        width: 800px;
        height: 560px;
        margin-left: 22px;
      }
    }
    .tatle5 {
      display: flex;
      > img {
        width: 832px;
        height: 534px;
      }
      .info5 {
        margin: 128px 0 0 12px;
        .info5tal {
          width: 416px;
          height: 47px;
          font-size: 32px;
          font-family: Source Han Sans SC-Bold, Source Han Sans SC;
          font-weight: bold;
          color: #161e2a;
          margin-bottom: 30px;
        }
        .info5tel {
          display: flex;
          width: 416px;
          margin-top: 20px;
          font-size: 20px;
          font-family: Source Han Sans SC-Regular, Source Han Sans SC;
          font-weight: 400;
          color: #2d394b;
          > img {
            width: 16px;
            height: 14px;
            margin-right: 12px;
            margin-top: 8px;
          }
        }
      }
    }
    .tatle6 {
      display: flex;
      .info6 {
        margin: 57px 0 0 60px;
        .info6tal {
          width: 406px;
          height: 47px;
          font-size: 32px;
          font-family: Source Han Sans SC-Bold, Source Han Sans SC;
          font-weight: bold;
          color: #161e2a;
          margin-bottom: 29px;
        }
        .info6tel {
          margin-top: 20px;
          .info6text {
            display: flex;
            align-items: center;
            width: 310px;
            height: 29px;
            font-size: 20px;
            font-family: Source Han Sans SC-Medium, Source Han Sans SC;
            font-weight: 500;
            color: #2d394b;
            > img {
              width: 16px;
              height: 14px;
              margin-right: 12px;
            }
          }
          .info6text2 {
            margin-left: 28px;
            margin-top: 10px;
            font-size: 14px;
            font-family: Source Han Sans SC-Normal, Source Han Sans SC;
            font-weight: 400;
            color: #818697;
          }
        }
      }
      > img {
        width: 832px;
        height: 534px;
        margin-left: 22px;
      }
    }
  }
  //次级导航-营销活动页面内容
  .dhinfo4 {
    width: 1320px;
    margin: 0 auto;
    padding: 70px 0 50px;
    .tatle1 {
      display: flex;
      > img {
        width: 832px;
        height: 534px;
      }
      .info1 {
        margin: 96px 0 0 128px;
        .info1tal {
          width: 300px;
          height: 89px;
          font-size: 32px;
          font-family: Source Han Sans SC-Bold, Source Han Sans SC;
          font-weight: bold;
          color: #2d394b;
          margin-bottom: 30px;
        }
        .info1tel {
          display: flex;
          margin-top: 16px;
          font-size: 20px;
          font-family: Source Han Sans SC-Regular, Source Han Sans SC;
          font-weight: 400;
          color: #2d394b;
          > img {
            width: 16px;
            height: 14px;
            margin-right: 12px;
            margin-top: 8px;
          }
        }
      }
    }
    .tatle2 {
      display: flex;
      .info2 {
        margin: 97px 0 0 60px;
        .info2tal {
          width: 406px;
          height: 89px;
          font-size: 32px;
          font-family: Source Han Sans SC-Bold, Source Han Sans SC;
          font-weight: bold;
          color: #2d394b;
          margin-bottom: 30px;
        }
        .bor {
          display: flex;
          .left {
            .info2tel {
              display: flex;
              margin-top: 16px;
              width: 116px;
              font-size: 20px;
              font-family: Source Han Sans SC-Regular, Source Han Sans SC;
              font-weight: 400;
              color: #2d394b;
              > img {
                width: 16px;
                height: 14px;
                margin-right: 12px;
                margin-top: 8px;
              }
              &:first-child {
                margin-top: 0;
              }
            }
          }
          .right {
            margin-left: 62px;
            .info2tel {
              display: flex;
              margin-top: 16px;
              width: 190px;
              font-size: 20px;
              font-family: Source Han Sans SC-Regular, Source Han Sans SC;
              font-weight: 400;
              color: #2d394b;
              > img {
                width: 16px;
                height: 14px;
                margin-right: 12px;
                margin-top: 8px;
              }
              &:first-child {
                margin-top: 0;
              }
            }
          }
        }
      }
      > img {
        width: 832px;
        height: 534px;
        margin-left: 22px;
      }
    }
  }
  //次级导航-经营数据页面内容
  .dhinfo5 {
    width: 1320px;
    margin: 0 auto;
    padding: 70px 0 50px;
    .tatle1 {
      display: flex;
      > img {
        width: 832px;
        height: 534px;
      }
      .info1 {
        margin: 90px 0 0 22px;
        .info1tal {
          width: 406px;
          height: 47px;
          font-size: 32px;
          font-family: Source Han Sans SC-Bold, Source Han Sans SC;
          font-weight: bold;
          color: #2d394b;
          margin-bottom: 30px;
        }
        .info1tel {
          display: flex;
          margin-top: 16px;
          font-size: 20px;
          font-family: Source Han Sans SC-Regular, Source Han Sans SC;
          font-weight: 400;
          color: #2d394b;
          > img {
            width: 16px;
            height: 14px;
            margin-right: 12px;
            margin-top: 8px;
          }
        }
      }
    }
    .tatle2 {
      display: flex;
      .info2 {
        margin: 98px 0 0 60px;
        .info2tal {
          width: 406px;
          height: 47px;
          font-size: 32px;
          font-family: Source Han Sans SC-Bold, Source Han Sans SC;
          font-weight: bold;
          color: #2d394b;
          margin-bottom: 30px;
        }
        .info2tel {
          display: flex;
          margin-top: 16px;
          font-size: 20px;
          font-family: Source Han Sans SC-Regular, Source Han Sans SC;
          font-weight: 400;
          color: #2d394b;
          > img {
            width: 16px;
            height: 14px;
            margin-right: 12px;
            margin-top: 8px;
          }
        }
      }
      > img {
        width: 832px;
        height: 534px;
        margin-left: 22px;
      }
    }
  }
  //次级导航-在线支付页面内容
  .dhinfo6 {
    width: 1320px;
    margin: 0 auto;
    padding: 120px 0 40px;
    .tatle1 {
      display: flex;
      > img {
        width: 660px;
        height: 400px;
        margin-left: 60px;
      }
      .info1 {
        margin: 63px 0 0 240px;
        .info1tal {
          width: 300px;
          height: 47px;
          font-size: 32px;
          font-family: Source Han Sans SC-Bold, Source Han Sans SC;
          font-weight: bold;
          color: #2d394b;
          margin-bottom: 30px;
        }
        .info1tel {
          display: flex;
          margin-top: 16px;
          font-size: 20px;
          font-family: Source Han Sans SC-Regular, Source Han Sans SC;
          font-weight: 400;
          color: #2d394b;
          > img {
            width: 16px;
            height: 14px;
            margin-right: 12px;
            margin-top: 8px;
          }
        }
      }
    }
    .tatle2 {
      display: flex;
      margin-top: 120px;
      .info2 {
        margin: 111px 0 0 60px;
        .info2tal {
          width: 406px;
          height: 47px;
          font-size: 32px;
          font-family: Source Han Sans SC-Bold, Source Han Sans SC;
          font-weight: bold;
          color: #2d394b;
          margin-bottom: 30px;
        }
        .info2tel {
          display: flex;
          margin-top: 16px;
          font-size: 20px;
          font-family: Source Han Sans SC-Regular, Source Han Sans SC;
          font-weight: 400;
          color: #2d394b;
          > img {
            width: 16px;
            height: 14px;
            margin-right: 12px;
            margin-top: 8px;
          }
        }
      }
      > img {
        width: 660px;
        height: 400px;
        margin-left: 134px;
      }
    }
    .tatle3 {
      display: flex;
      margin-top: 70px;
      > img {
        width: 865px;
        height: 534px;
      }
      .info3 {
        margin: 161px 0 0 95px;
        .info3tal {
          width: 300px;
          height: 47px;
          font-size: 32px;
          font-family: Source Han Sans SC-Bold, Source Han Sans SC;
          font-weight: bold;
          color: #2d394b;
          margin-bottom: 30px;
        }
        .info3tel {
          display: flex;
          margin-top: 16px;
          font-size: 20px;
          font-family: Source Han Sans SC-Regular, Source Han Sans SC;
          font-weight: 400;
          color: #2d394b;
          > img {
            width: 16px;
            height: 14px;
            margin-right: 12px;
            margin-top: 8px;
          }
        }
      }
    }
    .tatle4 {
      display: flex;
      margin-top: 19px;
      .info4 {
        margin: 113px 0 0 60px;
        .info4tal {
          width: 300px;
          height: 47px;
          margin-left: 20px;
          font-size: 32px;
          font-family: Source Han Sans SC-Bold, Source Han Sans SC;
          font-weight: bold;
          color: #2d394b;
          margin-bottom: 25px;
        }
        .info4tel {
          display: flex;
          align-items: center;
          margin-top: 8px;
          width: 360px;
          height: 60px;
          border-radius: 8px;
          border: 1px solid #fff;
          font-size: 20px;
          font-family: Source Han Sans SC-Regular, Source Han Sans SC;
          font-weight: 400;
          color: #2d394b;
          > img {
            width: 16px;
            height: 14px;
            margin-right: 12px;
            margin-left: 20px;
          }
          &:hover {
            cursor: pointer;
          }
        }
        //选中样式
        .info4che {
          background: #f4f5f9;
          border: 1px solid #008cee;
        }
      }
      .lunbo {
        width: 860px;
        height: 640px;
        margin-left: 40px;
        .el-carousel {
          width: 860px;
          height: 640px;
        }
        img {
          width: 860px;
          height: 640px;
        }
      }
    }
  }
  //次级导航-应用市场页面内容
  .dhinfo7 {
    width: 1200px;
    margin: 0 auto;
    padding: 120px 0;
    .val {
      margin: 0 auto;
      text-align: center;
      width: 801px;
      line-height: 42px;
      font-size: 28px;
      font-family: Source Han Sans SC-Regular, Source Han Sans SC;
      font-weight: 400;
      color: #2d394b;
    }
    .image {
      width: 1200px;
      height: 303px;
      margin-top: 40px;
      img {
        width: 1200px;
        height: 303px;
      }
    }
  }
}
</style>
