<template>
  <div class="robot1">
    <!-- 预订按钮 -->
    <div class="yuding" @click="robotyy">
      <img src="../../assets/img/yuding.png" />
    </div>
    <el-container>
      <!-- 页头 -->
      <el-header>
        <div class="maintitle">
          <img src="../../assets/img/robot2title.png" @click="robot0" />
          <div @click="robot0">首页</div>
          <div class="active" @click="robot1">产品介绍</div>
          <div @click="robot2">功能介绍</div>
          <div @click="robot3">更新说明</div>
        </div>
      </el-header>
      <!-- 主体内容 -->
      <el-main>
        <div class="robot1main"></div>
        <!-- 轮播图 -->
        <div class="lunbo">
          <div class="lunbotu">
            <!-- 上一篇 -->
            <div>
              <img
                src="../../assets/img/backleft.png"
                @click="arrowClick('prev')"
              />
            </div>
            <div class="quan">
              <el-carousel
                :autoplay="false"
                arrow="never"
                ref="cardShow"
                height="480px"
              >
                <el-carousel-item>
                  <img src="../../assets/img/robot1lb1.png" />
                </el-carousel-item>
                <el-carousel-item>
                  <img src="../../assets/img/robot1lb2.png" />
                </el-carousel-item>
                <el-carousel-item>
                  <img src="../../assets/img/robot1lb3.png" />
                </el-carousel-item>
              </el-carousel>
            </div>
            <!-- 下一篇 -->
            <div>
              <img
                src="../../assets/img/backright.png"
                @click="arrowClick('next')"
              />
            </div>
          </div>
          <div class="lunboval">
            <div>什么是8圈机器人？</div>
            <div>
              为了提升场所智慧经营，提高场所收入，精心打造的一款集AI人脸识别、无人收银系统、网民自助上网、智能财务管理等功能于一体的智能自助服务设备，与8圈计费完美结合，实现数据共享，统一云端存储，信息管理更安全。
            </div>
          </div>
        </div>
        <!-- 收银 -->
        <div class="cashier">
          <div class="cashiertitle">传统人工收银 VS 8圈机器人收银</div>
          <div class="carddiv">
            <div class="card">
              <div class="cardtitle">
                <img src="../../assets/img/cashier1.png" />
                <div>传统人工收银模式</div>
              </div>
              <div class="cardinfo">
                <div class="text">收银员成本高，招聘难，员工不稳定</div>
                <div class="xian"></div>
                <div class="text">人工收款，容易出错，财务统计困难</div>
                <div class="xian"></div>
                <div class="text">身份证冒用现象频发</div>
                <div class="xian"></div>
                <div class="text">不可控性较大（开卡耗时不稳定）</div>
                <div class="xian"></div>
                <div class="text">学习成本高（需要培训）</div>
                <div class="xian"></div>
              </div>
            </div>
            <div class="black">
              <img class="blackimg" src="../../assets/img/cashier3.png" />
              <div class="blacktitle">
                <img src="../../assets/img/cashier2.png" />
                <div>8圈机器人收银模式</div>
              </div>
              <div class="blackinfo">
                <div class="text">
                  <div>节约人工成本，网民自助上网，24小时全天守候</div>
                  <img src="../../assets/img/blackicon.png" />
                </div>
                <div class="xian"></div>
                <div class="text">
                  <div>机器的特质，自助收款一笔账，减少结账出错率</div>
                  <img src="../../assets/img/blackicon.png" />
                </div>
                <div class="xian"></div>
                <div class="text">
                  <div>AI智能人脸识别，网监安全认证，防盗刷身份证</div>
                  <img src="../../assets/img/blackicon.png" />
                </div>
                <div class="xian"></div>
                <div class="text">
                  <div>可控性强（耗时短10秒以内）</div>
                  <img src="../../assets/img/blackicon.png" />
                </div>
                <div class="xian"></div>
                <div class="text">
                  <div>0学习成本</div>
                  <img src="../../assets/img/blackicon.png" />
                </div>
                <div class="xian"></div>
              </div>
            </div>
          </div>
        </div>
        <!-- 产品优势 -->
        <div class="advantage">
          <div class="advantagetitle">产品优势</div>
          <div class="advantageinfo">
            <img src="../../assets/img/advantageimg.png" />
          </div>
        </div>
      </el-main>
      <!-- 底部 -->
      <el-footer>
        <div class="fotter">
          <div class="fotterdiv">
            <div class="yuedong">
              <img src="../../assets/img/8quanjqr.png" />
              <div class="text1">
                © Copyright 2008 yedone.com all rights reserved.
                渝网文[2013]0887-006号
              </div>
              <div class="text2">
                经营许可证：渝B2-20190234&nbsp;&nbsp;|&nbsp;&nbsp;<span @click="zhizhao">营业执照</span><br />渝ICP备09014244号-16&nbsp;&nbsp;|&nbsp;&nbsp;<span @click="gongyue">平台公约</span>
              </div>
              <div class="text3">
                <img src="../../assets/img/guohui.png" />
                <div class="text3tel" @click="beian">
                  渝公网安备 50010802002169号
                </div>
              </div>
            </div>
            <div class="bor">
              <div class="bor1"></div>
              <div class="bor2"></div>
            </div>
            <div class="contactUs">
              <div class="women">联系我们</div>
              <div class="phone">
                <div class="dianhua1">
                  <div>客服电话（24h）</div>
                  <div>400-680-8811</div>
                </div>
                <div class="dianhua2">
                  <div>商务电话（工作日09:00-18:00)</div>
                  <div>023-68636622</div>
                </div>
              </div>
              <div class="phone2">
                <div class="dianhua1">
                  <div>客服QQ（24h）</div>
                  <div>4006808811</div>
                </div>
                <div class="dianhua2">
                  <div>公司地址</div>
                  <div>重庆市南岸区南城大道199号正联大厦3楼</div>
                </div>
              </div>
            </div>
            <div class="attention">
              <div class="women">关注我们</div>
              <div class="erweima">
                <div class="fuwu">
                  <img src="../../assets/img/qushangwang.png" />
                  <div>去上网APP微信服务号</div>
                </div>
                <div class="gongzong">
                  <img src="../../assets/img/baquan.jpg" />
                  <div>8圈计费微信公众号</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </el-footer>
    </el-container>
  </div>
</template>
<script>
export default {
  name: "robot1",
  data() {
    return {};
  },
  mounted() {
    if (
      /Android|webOS|iPhone|iPod|BlackBerry|Harmony/i.test(navigator.userAgent)
    ) {
      //移动设备打开的网页，缩放页面尺寸
      /*
  		var clientWidth = document.body.clientWidth;
  		var rate = clientWidth / 1920;
  		*/
      var rate = 0.1;
      var meta = document.getElementsByTagName("meta");
      meta["viewport"].setAttribute(
        "content",
        "width=device-width,initial-scale=" + rate
      );
    }
  },
  methods: {
    //跳转首页
    robot0() {
      this.$router.push("/robot0");
    },
    //跳转产品介绍
    robot1() {
      this.$router.push("/robot1");
    },
    //跳转功能介绍
    robot2() {
      this.$router.push("/robot2");
    },
    //跳转更新说明
    robot3() {
      this.$router.push("/robot3");
    },
    //跳转预约订购
    robotyy() {
      // this.$router.push("/robotyy");
      //在新页面打开
      let routeUrl = this.$router.resolve("/robotyy");
      window.open(routeUrl.href, "_blank");
    },
    //轮播图点击翻页
    arrowClick(val) {
      if (val === "next") {
        this.$refs.cardShow.next();
      } else {
        this.$refs.cardShow.prev();
      }
    },
    //跳转备案
    beian() {
      window.open(
        "http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=50010802002169"
      );
    },
    //跳转营业执照
    zhizhao(){
      let routeData = this.$router.resolve('/yinye');
      window.open(routeData.href, '_blank');
    },
    gongyue(){
      window.open("http://8quan.com/pingtaigongyue.html")
    }
  },
};
</script>
<style lang="less" scoped>
//预约订购样式
.yuding {
  position: fixed;
  top: 70%;
  right: 25px;
  width: 130px;
  height: 140px;
  &:hover {
    cursor: pointer;
  }
  > img {
    width: 100%;
    height: 100%;
  }
}
// 页头样式
.el-header {
  width: 100%;
  min-width: 1200px;
  height: 80px !important;
  box-sizing: border-box;
  padding: 21px 0 0;
  margin: 0 auto;
  .maintitle {
    display: flex;
    align-items: center;
    width: 1200px;
    margin: 0 auto;
    > img {
      width: 182px;
      height: 40px;
      margin-right: 50px;
      &:hover {
        cursor: pointer;
      }
    }
    > div {
      width: 72px;
      height: 27px;
      text-align: center;
      font-size: 18px;
      font-family: Source Han Sans SC;
      font-weight: 500;
      line-height: 27px;
      color: #626778;
      margin-left: 80px;
      &:hover {
        cursor: pointer;
      }
    }
    .active {
      opacity: 1;
      position: relative;
      color: #0e6eff;
      &::after {
        content: "";
        position: absolute;
        width: 30px;
        height: 4px;
        background: #0e6eff;
        opacity: 1;
        bottom: -10px;
        left: 50%;
        transform: translate(-50%, 50%);
      }
    }
  }
}
// 主体内容样式
.el-main {
  width: 100%;
  min-width: 1200px;
  padding: 0;
  margin: 0 auto;
  .robot1main {
    margin: 0 auto;
    height: 640px;
    background-image: url(../../assets/img/robot1back2.png);
    background-size: cover;
    /* 将背景图片等比缩放填满整个容器 */
    background-repeat: no-repeat;
    background-position: center;
  }
  .lunbo {
    max-width: 1903px;
    width: 1200px;
    height: 600px;
    box-sizing: border-box;
    padding-top: 80px;
    margin: 0 auto;
    display: flex;
    .lunbotu {
      display: flex;
      align-items: center;
      height: 480px;
      .quan {
        width: 500px;
        height: 480px;
        img {
          width: 100%;
          height: 100%;
        }
        //轮播图指示器样式
        /deep/ .el-carousel__indicators--horizontal {
          li {
            padding: 0px 12px 37px;
          }
          button {
            width: 12px;
            height: 12px;
            border-radius: 50% !important;
            background: #d0d4e2;
          }
        }
        /deep/ .is-active button {
          background: #0e60ff;
        }
      }
    }
    .lunboval {
      margin: 60px 0 0 160px;
      > div:nth-child(1) {
        height: 54px;
        font-size: 36px;
        font-family: Source Han Sans SC;
        font-weight: bold;
        line-height: 54px;
        color: #2d394b;
      }
      > div:nth-child(2) {
        width: 440px;
        font-size: 24px;
        font-family: Source Han Sans SC;
        font-weight: 400;
        line-height: 40px;
        color: #626778;
        margin-top: 20px;
      }
    }
  }
  .cashier {
    height: 746px;
    box-sizing: border-box;
    padding-top: 80px;
    background: #f4f5f9;
    .cashiertitle {
      width: 805px;
      height: 54px;
      font-size: 36px;
      font-family: Source Han Sans SC;
      font-weight: bold;
      line-height: 54px;
      color: #2d394b;
      text-align: center;
      margin: 0 auto;
    }
    .carddiv {
      width: 1200px;
      height: 472px;
      margin: 60px auto 0;
      display: flex;
      justify-content: space-between;
      .card {
        width: 580px;
        height: 472px;
        background: #ffffff;
        border-radius: 16px;
        box-sizing: border-box;
        padding: 40px 0 0 40px;
        .cardtitle {
          display: flex;
          align-items: center;
          > img {
            width: 36px;
            height: 36px;
          }
          > div {
            height: 36px;
            font-size: 24px;
            font-family: Source Han Sans SC;
            font-weight: bold;
            line-height: 36px;
            color: #2d394b;
            margin-left: 10px;
          }
        }
        .cardinfo {
          margin-top: 40px;
          .text {
            height: 27px;
            font-size: 18px;
            font-family: Source Han Sans SC;
            font-weight: 400;
            line-height: 18px;
            color: #626778;
          }
          .xian {
            width: 500px;
            height: 0px;
            border: 1px solid #eeeeee;
            margin: 21px 0 19px;
          }
        }
      }
      .black {
        width: 580px;
        height: 472px;
        border-radius: 16px;
        background: #0b192b;
        box-sizing: border-box;
        padding: 40px 40px 0;
        position: relative;
        .blackimg {
          width: 86px;
          height: 86px;
          position: absolute;
          top: 0;
          right: 0;
        }
        .blacktitle {
          display: flex;
          align-items: center;
          > img {
            width: 36px;
            height: 36px;
          }
          > div {
            height: 36px;
            font-size: 24px;
            font-family: Source Han Sans SC;
            font-weight: bold;
            line-height: 36px;
            color: #ffffff;
            margin-left: 10px;
          }
        }
        .blackinfo {
          margin-top: 40px;
          .text {
            display: flex;
            align-items: center;
            justify-content: space-between;
            > div {
              height: 27px;
              font-size: 18px;
              font-family: Source Han Sans SC;
              font-weight: 400;
              line-height: 18px;
              color: #ffffff;
            }
          }
          .xian {
            width: 500px;
            height: 0px;
            border: 1px solid #ffffff;
            margin: 21px 0 19px;
            opacity: 0.1;
          }
        }
      }
    }
  }
  //产品优势
  .advantage {
    height: 717px;
    box-sizing: border-box;
    padding-top: 80px;
    .advantagetitle {
      width: 805px;
      height: 54px;
      font-size: 36px;
      font-family: Source Han Sans SC;
      font-weight: bold;
      line-height: 54px;
      color: #2d394b;
      margin: 0 auto;
      text-align: center;
    }
    .advantageinfo {
      width: 1140px;
      height: 526px;
      margin: 12px auto;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}

//底部
.el-footer {
  width: 100%;
  min-width: 1200px;
  margin: 0 auto;
  padding: 0;
  .fotter {
    height: 338px;
    background: #0b192b;
    padding-top: 51px;
    box-sizing: border-box;
    .fotterdiv {
      width: 1200px;
      height: 225px;
      margin: 0 auto;
      display: flex;
      .yuedong {
        width: 273px;
        height: 225px;
        font-size: 14px;
        font-family: Source Han Sans SC;
        font-weight: 400;
        line-height: 21px;
        color: #626778;
        > img {
          width: 182px;
          height: 40px;
        }
        .text1 {
          margin-top: 47px;
        }
        .text2 {
          margin-top: 14px;
          span:hover{cursor: pointer;color: #ffffff;}
        }
        .text3 {
          margin-top: 14px;
          display: flex;
          justify-items: center;
          img {
            margin-right: 10px;
          }
          .text3tel {
            &:hover {
              cursor: pointer;
              color: #ffffff;
            }
          }
        }
      }
      .bor {
        width: 1px;
        height: 225px;
        margin-left: 36px;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        .bor1 {
          flex: 1;
        }
        .bor2 {
          height: 120px;
          border: 1px solid #1b2c43;
        }
      }
      .contactUs {
        width: 475px;
        height: 218px;
        margin-left: 50px;
        margin-top: 9px;
        font-size: 20px;
        font-family: Source Han Sans SC;
        font-weight: 500;
        line-height: 18px;
        color: #ffffff;
        .women {
          width: 80px;
          height: 29px;
        }
        .phone,
        .phone2 {
          margin-top: 57px;
          display: flex;
          .dianhua1,
          .dianhua2 {
            div:nth-child(1) {
              height: 20px;
              font-size: 14px;
              font-weight: 400;
              color: #626778;
            }
            div:nth-child(2) {
              height: 22px;
              font-size: 18px;
              font-family: DIN;
              color: #ffffff;
              margin-top: 8px;
            }
          }
          .dianhua1 {
            width: 124px;
          }
          .dianhua2 {
            margin-left: 52px;
          }
        }
        .phone2 {
          margin-top: 30px;
          .dianhua2 div:nth-child(2) {
            font-family: Source Han Sans SC;
            font-size: 16px;
          }
        }
      }
      .attention {
        width: 298px;
        height: 218px;
        margin-left: 67px;
        margin-top: 9px;
        font-size: 20px;
        font-family: Source Han Sans SC;
        font-weight: 500;
        line-height: 18px;
        color: #ffffff;
        .women {
          width: 80px;
          height: 29px;
          margin-left: 10px;
        }
        .erweima {
          display: flex;
          margin-top: 51px;
          .fuwu,
          .gongzong {
            font-size: 14px;
            font-weight: 400;
            color: #626778;
            text-align: center;
            > img {
              width: 110px;
              height: 110px;
            }
            > div {
              margin-top: 8px;
            }
          }
          .gongzong {
            margin-left: 38px;
          }
        }
      }
    }
  }
}
</style>
