<template>
  <div class="download">
    <el-container>
      <!-- 页头 -->
      <el-header>
        <div class="title">
          <div class="image" @click="shouye">
            <img src="../assets/img/title.png" />
          </div>
          <div class="titleBtn">
            <div @click="shouye">首页</div>
            <div @click="product">产品介绍</div>
            <div @click="feature">功能介绍</div>
            <div @click="download" class="active">下载</div>
          </div>
          <div class="denglu" @click="logins">登录网吧后台</div>
        </div>
      </el-header>
      <!-- 主体内容 -->
      <el-main>
        <div class="downloadmain">
          <div class="title">下载【8圈智能场所管理平台】</div>
          <div class="val">经营更随心 管理更简单</div>
          <div class="card">
            <div class="down1">
              <img src="../assets/img/download1.png" />
              <div class="down1til">Windows</div>
              <div class="down1tex">服务端</div>
              <div class="xiazai">
                <div class="xacard" @click="xiazai1">
                  <div>通用版(河南除外)</div>
                  <div>版本号：{{ service.serviceAll_Version }}</div>
                </div>
                <div class="xacard" @click="xiazai2">
                  <div>河南版</div>
                  <div>版本号：{{ service.serviceHeNan_Version }}</div>
                </div>
              </div>
            </div>
            <div class="down2">
              <img src="../assets/img/download1.png" />
              <div class="down1til">Windows</div>
              <div class="down1tex">客户端</div>
              <div class="xiazai">
                <div class="xacard" @click="xiazai3">
                  <div>通用版（支持windows 10）</div>
                  <div>版本号：{{ service.clientAll_Version }}</div>
                </div>
                <div class="xacard" @click="xiazai4">
                  <div>通用版（支持windows 11）</div>
                  <div>版本号：{{ service.clientAll_Version_Win11 }}</div>
                </div>
              </div>
            </div>
            <!-- <div class="down2 down3">
              <img src="../assets/img/download1.png" />
              <div class="down1til">Steam 保护</div>
              <div class="down1tex">&nbsp;</div>
              <div class="xiazai">
                <div class="xacard" @click="xiazai5">
                  <div>Steam 保护</div>
                </div>
              </div>
            </div> -->
          </div>
          <div class="tishi">
            <div>
              <div>安装注意</div>
              <img src="../assets/img/download2.png" />
            </div>
            <div>
              1、服务端951版本更新至952版本时，需先交班一次再进行安装。<br>
              2、服务端952版本升级<span style="color:red">953</span>版本，必须要先交班，查看营业流水上报成功后再卸载重装服务端<span style="color:red">953</span>版本。<br>
              <!-- 3、服务端版本号若是低于953.19177的<span style="color:red">953</span>版本，升级需要卸载收银端后再覆盖安装。 -->
            </div>
          </div>
          <!-- <div class="title" style="margin-top:20px">下载【保护Steam 程序】</div>
          <div class="card">
            <div class="down2 down3">
              <img src="../assets/img/download1.png" />
              <div class="down1til">Windows</div>
              <div class="down1tex">保护Steam 程序</div>
              <div class="xiazai">
                <div class="xacard" @click="xiazai5">
                  <div>保护Steam 程序</div>
                </div>
              </div>
            </div>
          </div>
          <div class="tishi">
            <div>
              <div>使用说明</div>
              <img src="../assets/img/download2.png" />
            </div>
            <div>1、把保护Steam程序加入开机启动项即可。</div>
          </div> -->
        </div>
        <div class="update">
          <div class="title">更新日志</div>
          <div class="btn">
            <div :class="id == 1 ? 'dev' : ''" @click="btn(1)">
              服务端通用版（河南除外）
            </div>
            <div :class="id == 2 ? 'dev' : ''" @click="btn(2)">
              服务端河南版
            </div>
            <div :class="id == 3 ? 'dev' : ''" @click="btn(3)">客户端</div>
          </div>
          <div class="xian"></div>
          <div class="journal" v-if="id == 1">
            <div class="shuxian"></div>
            <!-- 更新的每个版本 -->
            <div class="val" v-for="(item, i) of journal" :key="i">
              <div class="date">{{ item.uptime }}</div>
              <div class="dian"></div>
              <div class="info">
                <div class="valtitle">
                  <div>{{ item.version }}</div>
                  <div class="new" v-show="item.isNew == 1">最新版本</div>
                  <div class="xiazai" @click="onDown(item.url)">下载此版本</div>
                </div>
                <!-- 一条日志一个 li -->
                <div class="li" v-for="(val, idx) of item.log" :key="idx">
                  <div></div>
                  <div>{{ idx + 1 }}.{{ val }}</div>
                </div>
              </div>
            </div>
          </div>
          <div class="journal" v-if="id == 2">
            <!-- 更新的每个版本 -->
            <div class="val" v-for="(item, i) of journal" :key="i">
              <div class="date">{{ item.uptime }}</div>
              <div class="dian"></div>
              <div class="info">
                <div class="valtitle">
                  <div>{{ item.version }}</div>
                  <div class="new" v-show="item.isNew == 1">最新版本</div>
                  <div class="xiazai" @click="onDown(item.url)">下载此版本</div>
                </div>
                <!-- 一条日志一个 li -->
                <div class="li" v-for="(val, idx) of item.log" :key="idx">
                  <div></div>
                  <div>{{ idx + 1 }}.{{ val }}</div>
                </div>
              </div>
            </div>
          </div>
          <div class="journal" v-if="id == 3">
            <!-- 更新的每个版本 -->
            <div class="val" v-for="(item, i) of journal" :key="i">
              <div class="date">{{ item.uptime }}</div>
              <div class="dian"></div>
              <div class="info">
                <div class="valtitle">
                  <div>{{ item.version }}</div>
                  <div class="new" v-show="item.isNew == 1">最新版本</div>
                  <div class="xiazai" @click="onDown(item.url)">下载此版本</div>
                </div>
                <!-- 一条日志一个 li -->
                <div class="li" v-for="(val, idx) of item.log" :key="idx">
                  <div></div>
                  <div>{{ idx + 1 }}.{{ val }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </el-main>
      <!-- 底部 -->
      <footerComponents></footerComponents>
    </el-container>
  </div>
</template>
<script>
import footerComponents from "../components/footer.vue";
export default {
  name: "download",
  components: { footerComponents },
  data() {
    return {
      id: 1,
      service: {}, //服务端、客户端下载
      journal: [], //更新日志
    };
  },
  mounted() {
    if (
      /Android|webOS|iPhone|iPod|BlackBerry|Harmony/i.test(navigator.userAgent)
    ) {
      //移动设备打开的网页，缩放页面尺寸
      /*
  		var clientWidth = document.body.clientWidth;
  		var rate = clientWidth / 1920;
  		*/
      var rate = 0.1;
      var meta = document.getElementsByTagName("meta");
      meta["viewport"].setAttribute(
        "content",
        "width=device-width,initial-scale=" + rate
      );
    }

    //发送请求，获取下载地址
    this.$http.get("/api/download").then((res) => {
      let a = JSON.parse(res.data);
      if (a.Code == 1) {
        let b = JSON.parse(a.Data);
        this.service = b;
      }
    });

    //获取更新日志下载地址
    this.ondownload(1);
  },
  methods: {
    //跳转首页
    shouye() {
      this.$router.push("/");
    },
    //点击转跳产品介绍
    product() {
      this.$router.push("/product");
    },
    //跳转功能介绍页面
    feature() {
      this.$router.push("/feature");
    },
    //跳转下载页面
    download() {
      this.$router.push("/download");
    },
    //登录网吧后台
    logins() {
      window.open("http://b.8quan.com");
    },
    //点击切换下载版本
    btn(e) {
      if (this.id != e) {
        this.id = e;
        this.ondownload(e);
      }
    },
    //服务器通用版
    xiazai1() {
      window.open(this.service.serviceAll);
    },
    //服务器河南版
    xiazai2() {
      window.open(this.service.serviceHeNan);
    },
    //客户端通用版
    xiazai3() {
      window.open(this.service.clientAll);
    },
    xiazai4(){
      window.open(this.service.clientAll_Win11);
    },
    xiazai5(){
      this.$http.post("/api/DownProtect").then((res) => {
        let a = JSON.parse(res.data);
        if (a.Code == 1 && a.Data != "") { // Code=1 且 数据不为空
          window.open(a.Data);
        }
      });
    },
    //下载历史版本
    onDown(val) {
      window.open(val);
    },
    //获取更新日志下载地址
    ondownload(id) {
      this.$http.get("/api/history?typeId=" + id).then((res) => {
        let a = JSON.parse(res.data);
        if (a.Code == 1 && a.Data != "") { // Code=1 且 数据不为空
          let b = JSON.parse(a.Data);
          b.forEach((item) => {
            item.log = JSON.parse(item.log);
          });
          this.journal = b;
        } else {
          this.journal = [];
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
// 页头样式
.el-header {
  width: 100%;
  min-width: 1320px;
  margin: 0 auto;
  padding: 0;
  height: 80px !important;
  line-height: 80px;
  .title {
    display: flex;
    align-items: center;
    width: 1200px;
    margin: 0 auto;
    .image {
      min-width: 286px;
      height: 48px;
      margin-right: 2px;
      img {
        width: 286px;
        height: 48px;
      }
      &:hover{
        cursor: pointer;
      }
    }
    .titleBtn {
      display: flex;
      align-items: center;
      height: 80px;
      box-sizing: border-box;
      > div {
        margin-left: 80px;
        min-width: 36px;
        height: 27px;
        font-size: 18px;
        font-family: Source Han Sans SC;
        font-weight: 500;
        line-height: 27px;
        color: #666666;
        text-align: center;
      }
      .active {
        position: relative;
        color: #3586f1;
      }
      .active::after {
        position: absolute;
        bottom: -10px;
        content: "";
        width: 30px;
        height: 4px;
        background: #3586f1;
        left: 50%;
        transform: translate(-50%);
      }
      :hover {
        cursor: pointer;
      }
    }
    .denglu {
      width: 120px;
      height: 40px;
      background: #3586f1;
      border-radius: 8px;
      font-size: 14px;
      font-family: Source Han Sans SC;
      font-weight: 500;
      line-height: 40px;
      color: #ffffff;
      text-align: center;
      margin-left: 256px;
      &:hover {
        cursor: pointer;
      }
    }
  }
}
.el-main {
  width: 100%;
  min-width: 1320px;
  padding: 0;
  margin: 0 auto;
  .downloadmain {
    // height: 735px;
    background-image: url(../assets/img/downloadback.png);
    background-size: cover;
    /* 将背景图片等比缩放填满整个容器 */
    background-repeat: no-repeat;
    box-sizing: border-box;
    padding-top: 80px;
    padding-bottom: 80px;
    .title {
      width: 735px;
      height: 71px;
      font-size: 48px;
      font-family: Source Han Sans SC;
      font-weight: 500;
      line-height: 71px;
      color: #161e2a;
      text-align: center;
      margin: 0 auto;
    }
    .val {
      width: 250px;
      height: 36px;
      font-size: 24px;
      font-family: Source Han Sans SC;
      font-weight: 400;
      line-height: 36px;
      color: #626778;
      text-align: center;
      margin: 12px auto 0;
    }
    .card {
      // width: 630px;
      height: 242px;
      margin: 32px auto 0;
      display: flex;
      justify-content: center;
      >div{margin-right: 30px;
        &:last-child{margin-right: 0;}
      }
      .down1,
      .down2 {
        width: 300px;
        height: 242px;
        background: #ffffff;
        border-radius: 16px;
        box-sizing: border-box;
        overflow: hidden;
        > img {
          margin: 45px 0 0 120px;
        }
        > .down1til {
          // width: 60px;
          height: 20px;
          font-size: 14px;
          font-family: Source Han Sans SC;
          font-weight: 400;
          line-height: 20px;
          color: #626778;
          margin: 12px auto 0;
          text-align: center;
        }
        > .down1tex {
          // width: 60px;
          height: 29px;
          font-size: 20px;
          font-family: Source Han Sans SC;
          font-weight: 500;
          line-height: 29px;
          color: #161e2a;
          margin: 30px auto 0;
          text-align: center;
        }
      }
      // 服务端动画
      .down1 {
        &:hover {
          .xiazai {
            transform: translate(0, -200px);
            opacity: 1;
          }
        }
        .xiazai {
          width: 300px;
          height: 242px;
          background: #ffffff;
          box-shadow: 0px 20px 40px rgba(90, 97, 107, 0.1);
          opacity: 0;
          border-radius: 16px;
          padding-top: 11px;
          transition-duration: 1s;
          .xacard {
            width: 260px;
            height: 80px;
            background: #3586f1;
            opacity: 1;
            border-radius: 10px;
            margin: 20px 20px 0;
            text-align: center;
            box-sizing: border-box;
            padding-top: 16px;
            > div:nth-child(1) {
              height: 27px;
              font-size: 18px;
              font-family: Source Han Sans SC;
              font-weight: 500;
              line-height: 27px;
              color: #ffffff;
            }
            > div:nth-child(2) {
              height: 20px;
              font-size: 14px;
              font-family: Source Han Sans SC;
              font-weight: 400;
              line-height: 20px;
              color: #ffffff;
              opacity: 0.8;
              margin-top: 2px;
            }
            &:hover {
              cursor: pointer;
            }
          }
        }
      }
      .down2 {
        &:hover {
          .xiazai {
            transform: translate(0, -200px);
            opacity: 1;
          }
        }
        .xiazai {
          width: 300px;
          height: 242px;
          background: #ffffff;
          box-shadow: 0px 20px 40px rgba(90, 97, 107, 0.1);
          opacity: 0;
          border-radius: 16px;
          padding-top: 11px;
          transition-duration: 1s;
          .xacard {
            width: 260px;
            height: 80px;
            background: #3586f1;
            opacity: 1;
            border-radius: 10px;
            margin: 20px 20px 0;
            text-align: center;
            box-sizing: border-box;
            padding-top: 16px;
            > div:nth-child(1) {
              height: 27px;
              font-size: 18px;
              font-family: Source Han Sans SC;
              font-weight: 500;
              line-height: 27px;
              color: #ffffff;
            }
            > div:nth-child(2) {
              height: 20px;
              font-size: 14px;
              font-family: Source Han Sans SC;
              font-weight: 400;
              line-height: 20px;
              color: #ffffff;
              opacity: 0.8;
              margin-top: 2px;
            }
            &:hover {
              cursor: pointer;
            }
          }
        }
      }
      .down3 {
        &:hover {
          .xiazai {
            transform: translate(0, -160px);
            opacity: 1;
          }
        }
        .xacard{
          padding-top: 0 !important;
          >div{height: 80px !important; line-height: 80px !important;}
        }
      }
    }
    .tishi {
      width: 1200px;
      background: #ffffff;
      border-radius: 16px;
      margin: 30px auto 0;
      box-sizing: border-box;
      padding: 30px 0 20px;
      > div:nth-child(1) {
        display: flex;
        align-items: center;
        margin-left: 30px;
        > div {
          width: 72px;
          height: 27px;
          font-size: 18px;
          font-family: Source Han Sans SC;
          font-weight: 500;
          line-height: 27px;
          color: #2d394b;
        }
        > img {
          width: 20px;
          height: 20px;
          margin-left: 8px;
        }
      }
      > div:nth-child(2) {
        width: 1140px;
        font-size: 14px;
        font-family: Source Han Sans SC;
        font-weight: 400;
        line-height: 21px;
        color: #626778;
        margin: 14px 0 0 30px;
      }
    }
  }
  .update {
    padding: 40px 0 58px;
    .title {
      width: 1200px;
      height: 47px;
      font-size: 32px;
      font-family: Source Han Sans SC;
      font-weight: bold;
      line-height: 47px;
      color: #2d394b;
      margin: 0 auto;
    }
    .btn {
      width: 1200px;
      margin: 20px auto 0;
      display: flex;
      align-items: center;
      .dev {
        position: relative;
        &::after {
          content: "";
          position: absolute;
          width: 54px;
          height: 8px;
          background: #3586f1;
          opacity: 1;
          border-radius: 4px;
          bottom: -8px;
          left: 50%;
          transform: translate(-50%, 50%);
        }
      }
      & > div {
        margin-right: 60px;
        height: 29px;
        font-size: 20px;
        font-family: Source Han Sans SC;
        font-weight: bold;
        line-height: 29px;
        color: #2d394b;
        &:hover {
          cursor: pointer;
        }
      }
    }
    .xian {
      width: 1200px;
      height: 0px;
      border: 1px solid #eeeeee;
      margin: 11px auto 0;
    }
    .journal {
      width: 1200px;
      margin: 31px auto 0;
      position: relative;
      &::after {
        content: "";
        position: absolute;
        top: 8px;
        left: 174px;
        width: 2px;
        height: ~"calc(100% - 8px)"; //less计算高度
        background: #f2f2f2;
      }
      .val {
        display: flex;
        margin-top: 41px;
        // 时间
        .date {
          width: 145px;
          height: 27px;
          font-size: 18px;
          font-family: Source Han Sans SC;
          font-weight: bold;
          line-height: 27px;
          color: #2d394b;
        }
        .dian {
          width: 12px;
          height: 12px;
          min-width: 12px;
          min-height: 12px;
          background: #acafb9;
          border-radius: 50%;
          margin: 8px 24px 0;
          z-index: 2;
        }
        // 列表
        .info {
          .valtitle {
            display: flex;
            align-items: center;
            margin-bottom: 12px;
            > div:nth-child(1) {
              height: 27px;
              font-size: 18px;
              font-family: Source Han Sans SC;
              font-weight: bold;
              line-height: 27px;
              color: #2d394b;
            }
            .new {
              width: 64px;
              height: 22px;
              border: 1px solid rgba(0, 0, 0, 0);
              background: linear-gradient(
                133deg,
                #40a3ff 0%,
                #2172ff 100%,
                #000000 100%
              );
              border-radius: 4px;
              font-size: 12px;
              font-family: Source Han Sans SC;
              font-weight: 500;
              line-height: 22px;
              color: #ffffff;
              text-align: center;
              margin-left: 8px;
            }
            .xiazai {
              width: 88px;
              height: 28px;
              background: rgba(53, 134, 241, 0.1);
              border-radius: 14px;
              font-size: 14px;
              font-family: Source Han Sans SC;
              font-weight: 500;
              line-height: 28px;
              color: #3586f1;
              text-align: center;
              margin-left: 60px;
              &:hover {
                cursor: pointer;
              }
            }
          }
          .li {
            display: flex;
            align-items: center;
            margin-top: 4px;
            > div:nth-child(1) {
              width: 8px;
              height: 8px;
              background: #3586f1;
              border-radius: 50%;
            }
            > div:nth-child(2) {
              max-width: 680px;
              font-size: 16px;
              font-family: Source Han Sans SC;
              font-weight: 400;
              line-height: 28px;
              color: #626778;
              margin-left: 8px;
            }
          }
        }
      }
      //去掉第一个列表上边距
      .val:first-child {
        margin-top: 0px;
      }
    }
  }
}
</style>
