<template>
  <div class="robot3">
    <!-- 预订按钮 -->
    <div class="yuding" @click="robotyy">
      <img src="../../assets/img/yuding.png" />
    </div>
    <el-container>
      <!-- 页头 -->
      <el-header>
        <div class="maintitle">
          <img src="../../assets/img/robot2title.png" @click="robot0" />
          <div @click="robot0">首页</div>
          <div @click="robot1">产品介绍</div>
          <div @click="robot2">功能介绍</div>
          <div class="active" @click="robot3">更新说明</div>
        </div>
      </el-header>
      <el-main>
        <div class="back"></div>
        <div class="journal">
          <div class="shuxian"></div>
          <!-- 更新的每个版本 -->
          <div class="val" v-for="(item, i) of changeLog" :key="i">
            <div class="date">{{ item.changeTime }}</div>
            <div class="dian"></div>
            <div class="info">
              <div class="valtitle">
                <div>{{ item.version }}</div>
                <div class="new" v-show="item.isNew == 1">最新版本</div>
              </div>
              <!-- 一条日志一个 li -->
              <div class="li" v-for="(val, idx) of item.log" :key="idx">
                <div></div>
                <div>{{ idx + 1 }}.{{ val }}</div>
              </div>
            </div>
          </div>
        </div>
      </el-main>
      <!-- 底部 -->
      <el-footer>
        <div class="fotter">
          <div class="fotterdiv">
            <div class="yuedong">
              <img src="../../assets/img/8quanjqr.png" />
              <div class="text1">
                © Copyright 2008 yedone.com all rights reserved.
                渝网文[2013]0887-006号
              </div>
              <div class="text2">
                经营许可证：渝B2-20190234&nbsp;&nbsp;|&nbsp;&nbsp;<span @click="zhizhao">营业执照</span><br />渝ICP备09014244号-16&nbsp;&nbsp;|&nbsp;&nbsp;<span @click="gongyue">平台公约</span>
              </div>
              <div class="text3">
                <img src="../../assets/img/guohui.png" />
                <div class="text3tel" @click="beian">
                  渝公网安备 50010802002169号
                </div>
              </div>
            </div>
            <div class="bor">
              <div class="bor1"></div>
              <div class="bor2"></div>
            </div>
            <div class="contactUs">
              <div class="women">联系我们</div>
              <div class="phone">
                <div class="dianhua1">
                  <div>客服电话（24h）</div>
                  <div>400-680-8811</div>
                </div>
                <div class="dianhua2">
                  <div>商务电话（工作日09:00-18:00)</div>
                  <div>023-68636622</div>
                </div>
              </div>
              <div class="phone2">
                <div class="dianhua1">
                  <div>客服QQ（24h）</div>
                  <div>4006808811</div>
                </div>
                <div class="dianhua2">
                  <div>公司地址</div>
                  <div>重庆市南岸区南城大道199号正联大厦3楼</div>
                </div>
              </div>
            </div>
            <div class="attention">
              <div class="women">关注我们</div>
              <div class="erweima">
                <div class="fuwu">
                  <img src="../../assets/img/qushangwang.png" />
                  <div>去上网APP微信服务号</div>
                </div>
                <div class="gongzong">
                  <img src="../../assets/img/baquan.jpg" />
                  <div>8圈计费微信公众号</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </el-footer>
    </el-container>
  </div>
</template>
<script>
export default {
  data() {
    return {
      changeLog: [], //更新日志
    };
  },
  methods: {
    //跳转首页
    robot0() {
      this.$router.push("/robot0");
    },
    //跳转产品介绍
    robot1() {
      this.$router.push("/robot1");
    },
    //跳转功能介绍
    robot2() {
      this.$router.push("/robot2");
    },
    //跳转更新说明
    robot3() {
      this.$router.push("/robot3");
    },
    //跳转预约订购
    robotyy() {
      // this.$router.push("/robotyy");
      //在新页面打开
      let routeUrl = this.$router.resolve("/robotyy");
      window.open(routeUrl.href, "_blank");
    },
    //跳转备案
    beian() {
      window.open(
        "http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=50010802002169"
      );
    },
    //跳转营业执照
    zhizhao(){
      let routeData = this.$router.resolve('/yinye');
      window.open(routeData.href, '_blank');
    },
    gongyue(){
      window.open("http://8quan.com/pingtaigongyue.html")
    }
  },
  mounted() {
    if (
      /Android|webOS|iPhone|iPod|BlackBerry|Harmony/i.test(navigator.userAgent)
    ) {
      //移动设备打开的网页，缩放页面尺寸
      /*
  		var clientWidth = document.body.clientWidth;
  		var rate = clientWidth / 1920;
  		*/
      var rate = 0.1;
      var meta = document.getElementsByTagName("meta");
      meta["viewport"].setAttribute(
        "content",
        "width=device-width,initial-scale=" + rate
      );
    }

    //发送请求，获取 更新日志
    this.$http.get("/api/robothistory").then((res) => {
      let a = JSON.parse(res.data);
      if (a.Code == 1 && a.Data != "") {
        // Code=1 且 数据不为空
        let b = JSON.parse(a.Data);
        b.forEach((item) => {
          item.log = JSON.parse(item.log);
        });
        this.changeLog = b;
      } else {
        this.changeLog = [];
      }
    });
  },
};
</script>
<style lang="less" scoped>
//预约订购样式
.yuding {
  position: fixed;
  top: 70%;
  right: 25px;
  width: 130px;
  height: 140px;
  &:hover {
    cursor: pointer;
  }
  > img {
    width: 100%;
    height: 100%;
  }
}
// 页头样式
.el-header {
  width: 100%;
  min-width: 1200px;
  height: 80px !important;
  box-sizing: border-box;
  padding: 21px 0 0;
  margin: 0 auto;
  .maintitle {
    display: flex;
    align-items: center;
    width: 1200px;
    margin: 0 auto;
    > img {
      width: 182px;
      height: 40px;
      margin-right: 50px;
      &:hover {
        cursor: pointer;
      }
    }
    > div {
      width: 72px;
      height: 27px;
      text-align: center;
      font-size: 18px;
      font-family: Source Han Sans SC;
      font-weight: 500;
      line-height: 27px;
      color: #626778;
      margin-left: 80px;
      &:hover {
        cursor: pointer;
      }
    }
    .active {
      opacity: 1;
      position: relative;
      color: #0e6eff;
      &::after {
        content: "";
        position: absolute;
        width: 30px;
        height: 4px;
        background: #0e6eff;
        opacity: 1;
        bottom: -10px;
        left: 50%;
        transform: translate(-50%, 50%);
      }
    }
  }
}
//主体内容样式
.el-main {
  width: 100%;
  min-width: 1200px;
  padding: 0;
  margin: 0 auto;
  .back {
    margin: 0 auto;
    height: 360px;
    background-image: url(../../assets/img/robot3back.png);
    background-size: cover;
    /* 将背景图片等比缩放填满整个容器 */
    background-repeat: no-repeat;
    background-position: center;
  }
  // 更新日志样式
  .journal {
    width: 1200px;
    margin: 60px auto 80px;
    position: relative;
    //分割线
    &::after {
      content: "";
      position: absolute;
      top: 8px;
      left: 174px;
      width: 2px;
      height: ~"calc(100% - 8px)"; //less计算高度
      background: #f2f2f2;
    }
    .val {
      display: flex;
      margin-top: 41px;
      // 时间
      .date {
        width: 145px;
        height: 27px;
        font-size: 18px;
        font-family: Source Han Sans SC;
        font-weight: bold;
        line-height: 27px;
        color: #2d394b;
      }
      .dian {
        width: 12px;
        height: 12px;
        min-width: 12px;
        min-height: 12px;
        background: #acafb9;
        border-radius: 50%;
        margin: 8px 24px 0;
        z-index: 2;
      }
      // 列表
      .info {
        .valtitle {
          display: flex;
          align-items: center;
          margin-bottom: 12px;
          > div:nth-child(1) {
            height: 27px;
            font-size: 18px;
            font-family: Source Han Sans SC;
            font-weight: bold;
            line-height: 27px;
            color: #2d394b;
          }
          .new {
            width: 64px;
            height: 22px;
            border: 1px solid rgba(0, 0, 0, 0);
            background: linear-gradient(
              133deg,
              #40a3ff 0%,
              #2172ff 100%,
              #000000 100%
            );
            border-radius: 4px;
            font-size: 12px;
            font-family: Source Han Sans SC;
            font-weight: 500;
            line-height: 22px;
            color: #ffffff;
            text-align: center;
            margin-left: 8px;
          }
          .xiazai {
            width: 88px;
            height: 28px;
            background: rgba(53, 134, 241, 0.1);
            border-radius: 14px;
            font-size: 14px;
            font-family: Source Han Sans SC;
            font-weight: 500;
            line-height: 28px;
            color: #3586f1;
            text-align: center;
            margin-left: 60px;
            &:hover {
              cursor: pointer;
            }
          }
        }
        .li {
          display: flex;
          margin-top: 4px;
          > div:nth-child(1) {
            width: 8px;
            height: 8px;
            background: #3586f1;
            border-radius: 50%;
            margin-top: 10px;
          }
          > div:nth-child(2) {
            max-width: 680px;
            font-size: 16px;
            font-family: Source Han Sans SC;
            font-weight: 400;
            line-height: 28px;
            color: #626778;
            margin-left: 8px;
          }
        }
      }
    }
    //去掉第一个列表上边距
    .val:first-child {
      margin-top: 0px;
    }
  }
}
//底部
.el-footer {
  width: 100%;
  min-width: 1200px;
  margin: 0 auto;
  padding: 0;
  .fotter {
    height: 338px;
    background: #0b192b;
    padding-top: 51px;
    box-sizing: border-box;
    .fotterdiv {
      width: 1200px;
      height: 225px;
      margin: 0 auto;
      display: flex;
      .yuedong {
        width: 273px;
        height: 225px;
        font-size: 14px;
        font-family: Source Han Sans SC;
        font-weight: 400;
        line-height: 21px;
        color: #626778;
        > img {
          width: 182px;
          height: 40px;
        }
        .text1 {
          margin-top: 47px;
        }
        .text2 {
          margin-top: 14px;
          span:hover{cursor: pointer;color: #ffffff;}
        }
        .text3 {
          margin-top: 14px;
          display: flex;
          justify-items: center;
          img {
            margin-right: 10px;
          }
          .text3tel {
            &:hover {
              cursor: pointer;
              color: #ffffff;
            }
          }
        }
      }
      .bor {
        width: 1px;
        height: 225px;
        margin-left: 36px;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        .bor1 {
          flex: 1;
        }
        .bor2 {
          height: 120px;
          border: 1px solid #1b2c43;
        }
      }
      .contactUs {
        width: 475px;
        height: 218px;
        margin-left: 50px;
        margin-top: 9px;
        font-size: 20px;
        font-family: Source Han Sans SC;
        font-weight: 500;
        line-height: 18px;
        color: #ffffff;
        .women {
          width: 80px;
          height: 29px;
        }
        .phone,
        .phone2 {
          margin-top: 57px;
          display: flex;
          .dianhua1,
          .dianhua2 {
            div:nth-child(1) {
              height: 20px;
              font-size: 14px;
              font-weight: 400;
              color: #626778;
            }
            div:nth-child(2) {
              height: 22px;
              font-size: 18px;
              font-family: DIN;
              color: #ffffff;
              margin-top: 8px;
            }
          }
          .dianhua1 {
            width: 124px;
          }
          .dianhua2 {
            margin-left: 52px;
          }
        }
        .phone2 {
          margin-top: 30px;
          .dianhua2 div:nth-child(2) {
            font-family: Source Han Sans SC;
            font-size: 16px;
          }
        }
      }
      .attention {
        width: 298px;
        height: 218px;
        margin-left: 67px;
        margin-top: 9px;
        font-size: 20px;
        font-family: Source Han Sans SC;
        font-weight: 500;
        line-height: 18px;
        color: #ffffff;
        .women {
          width: 80px;
          height: 29px;
          margin-left: 10px;
        }
        .erweima {
          display: flex;
          margin-top: 51px;
          .fuwu,
          .gongzong {
            font-size: 14px;
            font-weight: 400;
            color: #626778;
            text-align: center;
            > img {
              width: 110px;
              height: 110px;
            }
            > div {
              margin-top: 8px;
            }
          }
          .gongzong {
            margin-left: 38px;
          }
        }
      }
    }
  }
}
</style>
