import Vue from 'vue'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'

// axios.defaults.baseURL = 'http://apitest.yedone.com'; //测试地址
axios.defaults.baseURL = 'http://api2.yedone.com'; //正式地址
Vue.prototype.$http = axios
Vue.config.productionTip = false
Vue.use(ElementUI);

//回到顶部方法
Vue.prototype.$scrollTo = (x = 0, y = 0, type = 'smooth') => {
  window.scrollTo({
      top: x,
      left: y,
      behavior: type // 滚动行为：smooth平滑滚动，instant瞬间滚动，默认值auto，等同于instant
  })
}

new Vue({
  router,
  store,
  el: '#app',
  render: h => h(App)
}).$mount('#app')
