<template>
  <div class="robot2">
    <!-- 预订按钮 -->
    <div class="yuding" @click="robotyy">
      <img src="../../assets/img/yuding.png" />
    </div>
    <el-container>
      <!-- 页头 -->
      <el-header>
        <div class="maintitle">
          <img src="../../assets/img/robot2title.png" @click="robot0" />
          <div @click="robot0">首页</div>
          <div @click="robot1">产品介绍</div>
          <div class="active" @click="robot2">功能介绍</div>
          <div @click="robot3">更新说明</div>
        </div>
      </el-header>
      <el-main>
        <div class="back"></div>
        <!-- 核心功能 -->
        <div class="kernel">
          <div class="kernelinfo">
            <div class="val">
              <img src="../../assets/img/kernelback1.png" />
              <div class="valinfo">
                <img src="../../assets/img/val1.png" />
                <div class="valtal">自助上机</div>
                <div class="valtex">
                  支持二代身份证刷卡上机、去上网APP扫码上机，人脸识别快速激活
                </div>
                <div class="valimg">
                  <img src="../../assets/img/val2.png" />
                  <div>1.刷卡或扫码</div>
                </div>
                <img class="image" src="../../assets/img/val5.png" />
                <div class="valimg">
                  <img src="../../assets/img/val3.png" />
                  <div>2.人脸识别</div>
                </div>
                <img class="image" src="../../assets/img/val5.png" />
                <div class="valimg">
                  <img src="../../assets/img/val4.png" />
                  <div>3.点击 【激活上网】按钮</div>
                </div>
              </div>
            </div>
            <div class="val2">
              <div class="valinfo">
                <img src="../../assets/img/val2icon1.png" />
                <div class="val2tal">自助充值</div>
                <div class="val2tex">支持支付宝、微信、云闪付快捷充值网费</div>
                <div class="val2img">
                  <img src="../../assets/img/tx5.png" />
                  <img src="../../assets/img/tx4.png" />
                  <img src="../../assets/img/tx6.png" />
                </div>
              </div>
              <img src="../../assets/img/kernelback2.png" />
            </div>
            <div class="val3">
              <img src="../../assets/img/kernelback3.png" />
              <div class="val3info">
                <img src="../../assets/img/val3icon1.png" />
                <div class="val3tal">自助办理会员</div>
                <div class="val3tel">
                  <div class="texticon"></div>
                  <div class="textval">临时卡上机激活自助引导办理会员</div>
                </div>
                <div class="val3tel">
                  <div class="texticon"></div>
                  <div class="textval">
                    机器人可办理的会员等级和充值金额可自定义设置
                  </div>
                </div>
              </div>
            </div>
            <div class="val4">
              <div class="valinfo">
                <img src="../../assets/img/val4icon1.png" />
                <div class="val4tal">自助购买包时</div>
                <div class="val4tel">
                  <div class="texticon"></div>
                  <div class="textval">
                    自定义设置包时价格、优惠时段以及购买限制
                  </div>
                </div>
                <div class="val4tel">
                  <div class="texticon"></div>
                  <div class="textval">
                    支持使用账户余额、微信、支付宝、云闪付自助购买
                  </div>
                </div>
              </div>
              <img src="../../assets/img/kernelback4.png" />
            </div>
            <div class="val5">
              <img src="../../assets/img/kernelback5.png" />
              <div class="val5info">
                <img src="../../assets/img/val5icon1.png" />
                <div class="val5tal">自助下机</div>
                <div class="val5tel">
                  支持二代身份证刷卡或扫码下机，确认下机后，无现金充值的临时卡余额会直接退回原支付账户
                </div>
              </div>
            </div>
          </div>
        </div>
      </el-main>
      <!-- 底部 -->
      <el-footer>
        <div class="fotter">
          <div class="fotterdiv">
            <div class="yuedong">
              <img src="../../assets/img/8quanjqr.png" />
              <div class="text1">
                © Copyright 2008 yedone.com all rights reserved.
                渝网文[2013]0887-006号
              </div>
              <div class="text2">
                经营许可证：渝B2-20190234&nbsp;&nbsp;|&nbsp;&nbsp;<span @click="zhizhao">营业执照</span><br />渝ICP备09014244号-16&nbsp;&nbsp;|&nbsp;&nbsp;<span @click="gongyue">平台公约</span>
              </div>
              <div class="text3">
                <img src="../../assets/img/guohui.png" />
                <div class="text3tel" @click="beian">
                  渝公网安备 50010802002169号
                </div>
              </div>
            </div>
            <div class="bor">
              <div class="bor1"></div>
              <div class="bor2"></div>
            </div>
            <div class="contactUs">
              <div class="women">联系我们</div>
              <div class="phone">
                <div class="dianhua1">
                  <div>客服电话（24h）</div>
                  <div>400-680-8811</div>
                </div>
                <div class="dianhua2">
                  <div>商务电话（工作日09:00-18:00)</div>
                  <div>023-68636622</div>
                </div>
              </div>
              <div class="phone2">
                <div class="dianhua1">
                  <div>客服QQ（24h）</div>
                  <div>4006808811</div>
                </div>
                <div class="dianhua2">
                  <div>公司地址</div>
                  <div>重庆市南岸区南城大道199号正联大厦3楼</div>
                </div>
              </div>
            </div>
            <div class="attention">
              <div class="women">关注我们</div>
              <div class="erweima">
                <div class="fuwu">
                  <img src="../../assets/img/qushangwang.png" />
                  <div>去上网APP微信服务号</div>
                </div>
                <div class="gongzong">
                  <img src="../../assets/img/baquan.jpg" />
                  <div>8圈计费微信公众号</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </el-footer>
    </el-container>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  methods: {
    //跳转首页
    robot0() {
      this.$router.push("/robot0");
    },
    //跳转产品介绍
    robot1() {
      this.$router.push("/robot1");
    },
    //跳转功能介绍
    robot2() {
      this.$router.push("/robot2");
    },
    //跳转更新说明
    robot3() {
      this.$router.push("/robot3");
    },
    //跳转预约订购
    robotyy() {
      // this.$router.push("/robotyy");
      //在新页面打开
      let routeUrl = this.$router.resolve("/robotyy");
      window.open(routeUrl.href, "_blank");
    },
    //跳转备案
    beian() {
      window.open(
        "http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=50010802002169"
      );
    },
    //跳转营业执照
    zhizhao(){
      let routeData = this.$router.resolve('/yinye');
      window.open(routeData.href, '_blank');
    },
    gongyue(){
      window.open("http://8quan.com/pingtaigongyue.html")
    }
  },
  mounted() {
    if (
      /Android|webOS|iPhone|iPod|BlackBerry|Harmony/i.test(navigator.userAgent)
    ) {
      //移动设备打开的网页，缩放页面尺寸
      /*
  		var clientWidth = document.body.clientWidth;
  		var rate = clientWidth / 1920;
  		*/
      var rate = 0.1;
      var meta = document.getElementsByTagName("meta");
      meta["viewport"].setAttribute(
        "content",
        "width=device-width,initial-scale=" + rate
      );
    }
  },
};
</script>
<style lang="less" scoped>
//预约订购样式
.yuding {
  position: fixed;
  top: 70%;
  right: 25px;
  width: 130px;
  height: 140px;
  &:hover {
    cursor: pointer;
  }
  > img {
    width: 100%;
    height: 100%;
  }
}
// 页头样式
.el-header {
  width: 100%;
  min-width: 1200px;
  height: 80px !important;
  box-sizing: border-box;
  padding: 21px 0 0;
  margin: 0 auto;
  .maintitle {
    display: flex;
    align-items: center;
    width: 1200px;
    margin: 0 auto;
    > img {
      width: 182px;
      height: 40px;
      margin-right: 50px;
      &:hover {
        cursor: pointer;
      }
    }
    > div {
      width: 72px;
      height: 27px;
      text-align: center;
      font-size: 18px;
      font-family: Source Han Sans SC;
      font-weight: 500;
      line-height: 27px;
      color: #626778;
      margin-left: 80px;
      &:hover {
        cursor: pointer;
      }
    }
    .active {
      opacity: 1;
      position: relative;
      color: #0e6eff;
      &::after {
        content: "";
        position: absolute;
        width: 30px;
        height: 4px;
        background: #0e6eff;
        opacity: 1;
        bottom: -10px;
        left: 50%;
        transform: translate(-50%, 50%);
      }
    }
  }
}
.el-main {
  width: 100%;
  min-width: 1200px;
  padding: 0;
  margin: 0 auto;
  overflow-x: hidden;
  .back {
    margin: 0 auto;
    height: 300px;
    background-image: url(../../assets/img/robot2back.png);
    background-size: cover;
    /* 将背景图片等比缩放填满整个容器 */
    background-repeat: no-repeat;
    background-position: center;
  }
  //核心功能
  .kernel {
    box-sizing: border-box;
    background: #f4f5f9;
    padding: 80px 0 120px;
    .kernelinfo {
      width: 1200px;
      margin: 0 auto;
      .val {
        display: flex;
        .valinfo {
          margin: 9px 0 0 88px;
          .valtal {
            width: 267px;
            height: 59px;
            font-size: 40px;
            font-family: Source Han Sans SC;
            font-weight: 500;
            line-height: 59px;
            color: #2d394b;
            margin-top: 12px;
          }
          .valtex {
            width: 360px;
            font-size: 24px;
            font-family: Source Han Sans SC;
            font-weight: 400;
            line-height: 40px;
            color: #626778;
            margin: 20px 0;
          }
          .valimg {
            display: flex;
            align-items: center;
            > div {
              height: 27px;
              font-size: 18px;
              font-family: Source Han Sans SC;
              font-weight: 400;
              line-height: 27px;
              color: #666666;
              margin-left: 13px;
            }
          }
          .image {
            margin: 6px 0 6px 18px;
          }
        }
      }
      .val2 {
        display: flex;
        margin-top: 120px;
        > img {
          margin-left: 88px;
        }
        .valinfo {
          margin-top: 40px;
          width: 360px;
          .val2tal {
            width: 267px;
            height: 59px;
            font-size: 40px;
            font-family: Source Han Sans SC-Medium, Source Han Sans SC;
            font-weight: 500;
            color: #2d394b;
            margin-top: 12px;
          }
          .val2tex {
            font-size: 24px;
            font-family: Source Han Sans SC-Regular, Source Han Sans SC;
            font-weight: 400;
            line-height: 40px;
            color: #626778;
            margin-top: 20px;
          }
          .val2img {
            margin-top: 30px;
            display: flex;
            img {
              width: 88px;
              height: 88px;
              margin-right: 40px;
            }
          }
        }
      }
      .val3 {
        display: flex;
        margin-top: 120px;
        .val3info {
          width: 360px;
          margin: 40px 0 0 88px;
          .val3tal {
            height: 59px;
            font-size: 40px;
            font-family: Source Han Sans SC;
            font-weight: 500;
            line-height: 59px;
            color: #2d394b;
            margin: 12px 0 30px;
          }
          .val3tel {
            display: flex;
            margin-top: 20px;
            .texticon {
              width: 14px;
              height: 14px;
              min-width: 14px;
              min-height: 14px;
              background: linear-gradient(317deg, #3d8aff 0%, #3cadff 100%);
              opacity: 1;
              border: 1px solid rgba(0, 0, 0, 0);
              border-radius: 50%;
              margin-right: 8px;
              margin-top: 8px;
            }
            .textval {
              line-height: 36px;
              font-size: 24px;
              font-family: Source Han Sans SC-Regular, Source Han Sans SC;
              font-weight: 400;
              color: #626778;
            }
          }
        }
      }
      .val4 {
        display: flex;
        margin-top: 120px;
        > img {
          margin-left: 88px;
        }
        .valinfo {
          margin-top: 40px;
          width: 360px;
          .val4tal {
            width: 267px;
            height: 59px;
            font-size: 40px;
            font-family: Source Han Sans SC-Medium, Source Han Sans SC;
            font-weight: 500;
            color: #2d394b;
            margin-top: 12px;
          }
          .val4tel {
            display: flex;
            margin-top: 20px;
            .texticon {
              width: 14px;
              height: 14px;
              min-width: 14px;
              min-height: 14px;
              background: linear-gradient(317deg, #3d8aff 0%, #3cadff 100%);
              opacity: 1;
              border: 1px solid rgba(0, 0, 0, 0);
              border-radius: 50%;
              margin-right: 8px;
              margin-top: 8px;
            }
            .textval {
              line-height: 36px;
              font-size: 24px;
              font-family: Source Han Sans SC-Regular, Source Han Sans SC;
              font-weight: 400;
              color: #626778;
            }
          }
        }
      }
      .val5 {
        display: flex;
        margin-top: 120px;
        .val5info {
          width: 360px;
          margin: 40px 0 0 64px;
          .val5tal {
            height: 59px;
            font-size: 40px;
            font-family: Source Han Sans SC;
            font-weight: 500;
            line-height: 59px;
            color: #2d394b;
            margin: 12px 0 20px;
          }
          .val5tel {
            width: 360px;
            font-size: 24px;
            line-height: 36px;
            font-family: Source Han Sans SC-Regular, Source Han Sans SC;
            font-weight: 400;
            color: #626778;
          }
        }
      }
    }
  }
}
//底部
.el-footer {
  width: 100%;
  min-width: 1200px;
  margin: 0 auto;
  padding: 0;
  .fotter {
    height: 338px;
    background: #0b192b;
    padding-top: 51px;
    box-sizing: border-box;
    .fotterdiv {
      width: 1200px;
      height: 225px;
      margin: 0 auto;
      display: flex;
      .yuedong {
        width: 273px;
        height: 225px;
        font-size: 14px;
        font-family: Source Han Sans SC;
        font-weight: 400;
        line-height: 21px;
        color: #626778;
        > img {
          width: 182px;
          height: 40px;
        }
        .text1 {
          margin-top: 47px;
        }
        .text2 {
          margin-top: 14px;
          span:hover{cursor: pointer;color: #ffffff;}
        }
        .text3 {
          margin-top: 14px;
          display: flex;
          justify-items: center;
          img {
            margin-right: 10px;
          }
          .text3tel {
            &:hover {
              cursor: pointer;
              color: #ffffff;
            }
          }
        }
      }
      .bor {
        width: 1px;
        height: 225px;
        margin-left: 36px;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        .bor1 {
          flex: 1;
        }
        .bor2 {
          height: 120px;
          border: 1px solid #1b2c43;
        }
      }
      .contactUs {
        width: 475px;
        height: 218px;
        margin-left: 50px;
        margin-top: 9px;
        font-size: 20px;
        font-family: Source Han Sans SC;
        font-weight: 500;
        line-height: 18px;
        color: #ffffff;
        .women {
          width: 80px;
          height: 29px;
        }
        .phone,
        .phone2 {
          margin-top: 57px;
          display: flex;
          .dianhua1,
          .dianhua2 {
            div:nth-child(1) {
              height: 20px;
              font-size: 14px;
              font-weight: 400;
              color: #626778;
            }
            div:nth-child(2) {
              height: 22px;
              font-size: 18px;
              font-family: DIN;
              color: #ffffff;
              margin-top: 8px;
            }
          }
          .dianhua1 {
            width: 124px;
          }
          .dianhua2 {
            margin-left: 52px;
          }
        }
        .phone2 {
          margin-top: 30px;
          .dianhua2 div:nth-child(2) {
            font-family: Source Han Sans SC;
            font-size: 16px;
          }
        }
      }
      .attention {
        width: 298px;
        height: 218px;
        margin-left: 67px;
        margin-top: 9px;
        font-size: 20px;
        font-family: Source Han Sans SC;
        font-weight: 500;
        line-height: 18px;
        color: #ffffff;
        .women {
          width: 80px;
          height: 29px;
          margin-left: 10px;
        }
        .erweima {
          display: flex;
          margin-top: 51px;
          .fuwu,
          .gongzong {
            font-size: 14px;
            font-weight: 400;
            color: #626778;
            text-align: center;
            > img {
              width: 110px;
              height: 110px;
            }
            > div {
              margin-top: 8px;
            }
          }
          .gongzong {
            margin-left: 38px;
          }
        }
      }
    }
  }
}
</style>
