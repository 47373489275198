<template>
  <div id="app">
    <div class="goTop" @click="goTop">
      <img src="../src/assets/img/goTop.png">
    </div>
    <!-- 路由跳转页面不刷新，加随机key -->
    <router-view :key="$route.fullPath" />
  </div>
</template>
<script>
export default {
  methods: {
    //回到顶部
    goTop(){
      this.$scrollTo()
    }
  },
}
</script>
<style lang="less">
// 清除所有默认内外边距
* {
  margin: 0;
  padding: 0;
}
#app{width: 100%;height: 100vh;}
/* 返回顶部样式 */
#app .goTop{
  position: fixed;
  bottom: 48px;
  right: 24px;
  width: 48px;
  height: 48px;
  background: rgba(0, 0, 0, .5);
  border-radius: 10px 10px 10px 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}
#app .goTop:hover{
  cursor: pointer;
  background: rgba(0, 0, 0, 1);
}
#app .goTop img{
  width: 20px;
  height: 20px;
}
// 全局修改多行文本输入textarea 样式
textarea {
  padding: 12px 13px !important;
  font-size: 16px !important;
  font-family: Source Han Sans SC !important;
  font-weight: 400 !important;
  color: #2d394b !important;
}
</style>
