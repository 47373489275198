import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)
//获取原型对象上的push函数
const originalPush = VueRouter.prototype.push
//修改原型对象中的push方法
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta:{title: '8圈智能场所管理平台'}
  },
  // 产品介绍
  {
    path: '/product',
    name: 'product',
    component: () => import(/* webpackChunkName: "product" */ '../views/product.vue'),
    meta:{title: '8圈智能场所管理平台'}
  },
  // 功能介绍
  {
    path: '/feature',
    name: 'feature',
    component: () => import(/* webpackChunkName: "feature" */ '../views/feature.vue'),
    meta:{title: '8圈智能场所管理平台'}
  },
  //下载
  {
    path: '/download',
    name: 'download',
    component: () => import(/* webpackChunkName: "download" */ '../views/download.vue'),
    meta:{title: '8圈智能场所管理平台'}
  },
  //8圈机器人首页
  {
    path: '/robot0',
    name: 'robot0',
    component: () => import(/* webpackChunkName: "robot0" */ '../views/robot/robot0.vue'),
    meta:{title: '8圈机器人', icon: 'favicon2.ico'}
  },
  //8圈机器人产品介绍
  {
    path: '/robot1',
    name: 'robot1',
    component: () => import(/* webpackChunkName: "robot1" */ '../views/robot/robot1.vue'),
    meta:{title: '8圈机器人', icon: 'favicon2.ico'}
  },
  //8圈机器人功能介绍
  {
    path: '/robot2',
    name: 'robot2',
    component: () => import(/* webpackChunkName: "robot2" */ '../views/robot/robot2.vue'),
    meta:{title: '8圈机器人', icon: 'favicon2.ico'}
  },
  //8圈机器人更新说明
  {
    path: '/robot3',
    name: 'robot3',
    component: () => import(/* webpackChunkName: "robot3" */ '../views/robot/robot3.vue'),
    meta:{title: '8圈机器人', icon: 'favicon2.ico'}
  },
  //8圈机器人预约订购
  {
    path: '/robotyy',
    name: 'robotyy',
    component: () => import(/* webpackChunkName: "robotyy" */ '../views/robot/robotyy.vue'),
    meta:{title: '8圈机器人', icon: 'favicon2.ico'}
  },
  //8圈鹰眼首页
  {
    path: '/eagleEye0',
    name: 'eagleEye0',
    component: () => import(/* webpackChunkName: "eagleEye0" */ '../views/eagleEye/eagleEye0.vue'),
    meta:{title: '8圈鹰眼'}
  },
  //8圈鹰眼产品介绍
  {
    path: '/eagleEye1',
    name: 'eagleEye1',
    component: () => import(/* webpackChunkName: "eagleEye1" */ '../views/eagleEye/eagleEye1.vue'),
    meta:{title: '8圈鹰眼'}
  },
  //8圈鹰眼功能介绍
  {
    path: '/eagleEye2',
    name: 'eagleEye2',
    component: () => import(/* webpackChunkName: "eagleEye2" */ '../views/eagleEye/eagleEye2.vue'),
    meta:{title: '8圈鹰眼'}
  },
  //8圈鹰眼更新说明
  {
    path: '/eagleEye3',
    name: 'eagleEye3',
    component: () => import(/* webpackChunkName: "eagleEye3" */ '../views/eagleEye/eagleEye3.vue'),
    meta:{title: '8圈鹰眼'}
  },
  //去上网首页
  {
    path: '/gonetwork0',
    name: 'gonetwork0',
    component: () => import(/* webpackChunkName: "gonetwork0" */ '../views/gonetwork/gonetwork0.vue'),
    meta:{title: '去上网', icon: 'favicon3.ico'}
  },
  //去上网产品介绍
  {
    path: '/gonetwork1',
    name: 'gonetwork1',
    component: () => import(/* webpackChunkName: "gonetwork1" */ '../views/gonetwork/gonetwork1.vue'),
    meta:{title: '去上网', icon: 'favicon3.ico'}
  },
  //去上网功能介绍
  {
    path: '/gonetwork2',
    name: 'gonetwork2',
    component: () => import(/* webpackChunkName: "gonetwork2" */ '../views/gonetwork/gonetwork2.vue'),
    meta:{title: '去上网', icon: 'favicon3.ico'}
  },
  //去上网下载
  {
    path: '/gonetwork3',
    name: 'gonetwork3',
    component: () => import(/* webpackChunkName: "gonetwork3" */ '../views/gonetwork/gonetwork3.vue'),
    meta:{title: '去上网', icon: 'favicon3.ico'}
  },
  //掌上8圈首页
  {
    path: '/palm0',
    name: 'palm0',
    component: () => import(/* webpackChunkName: "palm0" */ '../views/palm/palm0.vue'),
    meta:{title: '掌上8圈'}
  },
  //掌上8圈产品介绍
  {
    path: '/palm1',
    name: 'palm1',
    component: () => import(/* webpackChunkName: "palm1" */ '../views/palm/palm1.vue'),
    meta:{title: '掌上8圈'}
  },
  //掌上8圈功能介绍
  {
    path: '/palm2',
    name: 'palm2',
    component: () => import(/* webpackChunkName: "palm2" */ '../views/palm/palm2.vue'),
    meta:{title: '掌上8圈'}
  },
  //掌上8圈下载
  {
    path: '/palm3',
    name: 'palm3',
    component: () => import(/* webpackChunkName: "palm3" */ '../views/palm/palm3.vue'),
    meta:{title: '掌上8圈'}
  },
  {
    path: '/yinye',
    name: 'yinye',
    component: () => import(/* webpackChunkName: "yinye" */ '../views/yinye.vue')
  },
]

const router = new VueRouter({
  routes,
  //刷新页面时回顶部
  scrollBehavior (to, from, savedPosition) {
    return { x: 0, y: 0}
  }
})

router.beforeEach((to, from, next) => {
	/* 路由发生变化修改页面title */
	if (to.meta.title) {
		document.title = to.meta.title
	}
  /* 路由发生变化修改页面icon */
	if (to.meta.icon) {
		document.querySelector('link[rel="icon"]').href = to.meta.icon;
	}else{
		document.querySelector('link[rel="icon"]').href = 'favicon.ico';
	}
	next()
})

export default router
