<template>
  <div class="product">
    <el-container>
      <!-- 页头 -->
      <el-header>
        <div class="title">
          <div class="image" @click="shouye">
            <img src="../assets/img/title.png" />
          </div>
          <div class="titleBtn">
            <div @click="shouye">首页</div>
            <div @click="product" class="active">产品介绍</div>
            <div @click="feature">功能介绍</div>
            <div @click="download">下载</div>
          </div>
          <div class="denglu" @click="logins">登录网吧后台</div>
        </div>
      </el-header>
      <!-- 主体内容 -->
      <el-main>
        <div class="featuremain">
          <el-carousel trigger="click" height="640px" arrow="never">
            <el-carousel-item>
              <!-- <img src="../assets/img/featureback1.png" /> -->
              <div class="divback" :style="'background-image: url('+require('../assets/img/featureback1.png')+')'"></div>
            </el-carousel-item>
            <el-carousel-item>
              <!-- <img src="../assets/img/featureback2.png" /> -->
              <div class="divback" :style="'background-image: url('+require('../assets/img/featureback2.png')+')'"></div>
            </el-carousel-item>
            <el-carousel-item>
              <!-- <img src="../assets/img/featureback3.png" /> -->
              <div class="divback" :style="'background-image: url('+require('../assets/img/featureback3.png')+')'"></div>
            </el-carousel-item>
          </el-carousel>
        </div>
        <div class="lunbo">
          <div class="back">
            <div class="lunbotu">
              <!-- 上一篇 -->
              <div class="icon">
                <img
                  src="../assets/img/backleft.png"
                  @click="arrowClick('prev')"
                />
              </div>
              <div class="quan">
                <el-carousel
                  indicator-position="outside"
                  :autoplay="false"
                  arrow="never"
                  ref="cardShow"
                  height="380px"
                >
                  <el-carousel-item>
                    <img src="../assets/img/productlb1.png" />
                  </el-carousel-item>
                  <el-carousel-item>
                    <img src="../assets/img/productlb2.png" />
                  </el-carousel-item>
                  <el-carousel-item>
                    <img src="../assets/img/productlb3.png" />
                  </el-carousel-item>
                </el-carousel>
              </div>
              <!-- 下一篇 -->
              <div class="icon">
                <img
                  src="../assets/img/backright.png"
                  @click="arrowClick('next')"
                />
              </div>
            </div>
            <div class="info">
              <img src="../assets/img/productinfo.png" />
              <div class="text1">
                8圈智能场所管理平台，是专为场所经营管理量身打造的智能管理系统，主要应用于互联网上网服务场所、电竞馆、电竞酒店、民宿等场所的经营管理。
              </div>
              <div class="text2">
                为场所提供全方位的管理、营销和增值服务方案，以及专属智能管理服务，方便场所日常管理和经营分析，提高经营管理效率，节省人力和时间成本。
              </div>
            </div>
          </div>
        </div>
        <div class="advantage">
          <div class="advantagetitle">平台优势</div>
          <div class="advantageinfo">
            <div class="info1">
              <img class="image1" src="../assets/img/advantage1.png" />
              <div class="tal">安全</div>
              <div class="text">
                数据采用加密传输和储存，一旦发生异常立即报警，提示终端底层驱动保护，云端和本地双重数据备份，保证经营数据安全。
              </div>
              <img class="image2" src="../assets/img/advantage2.png" />
              <div class="tal">易用</div>
              <div class="text">
                全新的交互体验；<br />功能模块化，自主编辑；<br />界面简洁，操作简单。
              </div>
            </div>
            <div class="info2">
              <img src="../assets/img/advantage5.png" />
            </div>
            <div class="info3">
              <img class="image1" src="../assets/img/advantage3.png" />
              <div class="tal">准确</div>
              <div class="text">
                以金额为计费单元，避免跳费误差；<br />多端数据智能化校对，提供实时准确的数据分析。
              </div>
              <img class="image2" src="../assets/img/advantage4.png" />
              <div class="tal">稳定</div>
              <div class="text">
                采用全新的架构，最大程度保证程序的自身强壮与稳定，数据云端储存在云端，确保永不宕机。
              </div>
            </div>
          </div>
        </div>
        <div class="platform">
          <div class="platformtitle">平台亮点</div>
          <div class="platforminfo">
            <div class="tel">
              <img src="../assets/img/platform1.png" />
              <div class="info">
                <div class="title">全新的架构</div>
                <div class="text">
                  基于云中心数据管理结构，为大数据平台打下坚实的基础，与市场上其他产品相比区别在于，所有架构都是以大数据分析管理为前提，为上网服务场所提供实时准确的数据分析，让经营状况一目了然。
                </div>
              </div>
            </div>
            <div class="tel2">
              <div class="info">
                <div class="title">全新的管理模式</div>
                <div class="text">
                  加入全新的员工管理系统；针对市场连锁管理痛点，独创连锁、联盟、弱联盟、监控四种模式；基于互联网，引进微信、支付宝在线支付结算平台，让场所管理更简单。
                </div>
              </div>
              <img src="../assets/img/platform2.png" />
            </div>
            <div class="tel3">
              <img src="../assets/img/platform3.png" />
              <div class="info">
                <div class="title">全新的经营理念</div>
                <div class="text">
                  基于互联网开放、分享的理念，打造全新开放平台，所有应用开发者或应用提供者都可通过开发平台为场所提供更多优秀的应用。
                </div>
              </div>
            </div>
          </div>
        </div>
      </el-main>
      <!-- 底部 -->
      <footerComponents></footerComponents>
    </el-container>
  </div>
</template>
<script>
import footerComponents from "../components/footer.vue";
export default {
  name: "product",
  components: { footerComponents },
  data() {
    return {};
  },
  mounted() {
    if (
      /Android|webOS|iPhone|iPod|BlackBerry|Harmony/i.test(navigator.userAgent)
    ) {
      //移动设备打开的网页，缩放页面尺寸
      /*
  		var clientWidth = document.body.clientWidth;
  		var rate = clientWidth / 1920;
  		*/
      var rate = 0.1;
      var meta = document.getElementsByTagName("meta");
      meta["viewport"].setAttribute(
        "content",
        "width=device-width,initial-scale=" + rate
      );
    }
  },
  methods: {
    //跳转首页
    shouye() {
      this.$router.push("/");
    },
    //点击转跳产品介绍
    product() {
      this.$router.push("/product");
    },
    //跳转功能介绍页面
    feature() {
      this.$router.push("/feature");
    },
    //跳转下载页面
    download() {
      this.$router.push("/download");
    },
    //登录网吧后台
    logins() {
      window.open("http://b.8quan.com");
    },
    //轮播图点击翻页
    arrowClick(val) {
      if (val === "next") {
        this.$refs.cardShow.next();
      } else {
        this.$refs.cardShow.prev();
      }
    },
  },
};
</script>
<style lang="less" scoped>
// 页头样式
.el-header {
  width: 100%;
  min-width: 1320px;
  padding: 0;
  margin: 0 auto;
  height: 80px !important;
  line-height: 80px;
  .title {
    display: flex;
    align-items: center;
    width: 1200px;
    margin: 0 auto;
    .image {
      min-width: 286px;
      height: 48px;
      margin-right: 2px;
      img {
        width: 286px;
        height: 48px;
      }
      &:hover {
        cursor: pointer;
      }
    }
    .titleBtn {
      display: flex;
      align-items: center;
      height: 80px;
      box-sizing: border-box;
      > div {
        margin-left: 80px;
        min-width: 36px;
        height: 27px;
        font-size: 18px;
        font-family: Source Han Sans SC;
        font-weight: 500;
        line-height: 27px;
        color: #666666;
        text-align: center;
      }
      .active {
        position: relative;
        color: #3586f1;
      }
      .active::after {
        position: absolute;
        bottom: -10px;
        content: "";
        width: 30px;
        height: 4px;
        background: #3586f1;
        left: 50%;
        transform: translate(-50%);
      }
      :hover {
        cursor: pointer;
      }
    }
    .denglu {
      width: 120px;
      height: 40px;
      background: #3586f1;
      border-radius: 8px;
      font-size: 14px;
      font-family: Source Han Sans SC;
      font-weight: 500;
      line-height: 40px;
      color: #ffffff;
      text-align: center;
      margin-left: 256px;
      &:hover {
        cursor: pointer;
      }
    }
  }
}
.el-main {
  width: 100%;
  min-width: 1320px;
  margin: 0 auto;
  padding: 0;
  .featuremain {
    width: 100%;
    height: 640px;
    margin: 0 auto;
    // 轮播图总体大小
    /deep/.el-carousel--horizontal {
      margin: 0 auto;
      min-width: 1320px;
      // 增加每个轮播图大小，避免出现白色间隙
      .el-carousel__item{
        width: calc(~'100% + 2px');
      }
      .divback{
        height: 100%;
        background-size: cover;
        /* 将背景图片等比缩放填满整个容器 */
        background-repeat: no-repeat;
        background-position:center;
      }
    }
    //轮播图指示器样式
    /deep/ .el-carousel__indicators--horizontal {
      bottom: 28px;
      li {
        padding: 12px;
      }
      button {
        width: 40px;
        height: 8px;
        background: #ffffff;
        opacity: 0.4;
        border-radius: 4px;
      }
    }
    /deep/ .is-active button {
      background: #ffffff;
      opacity: 1;
    }
  }
  .lunbo {
    height: 600px;
    .back {
      width: 1200px;
      height: 440px;
      display: flex;
      box-sizing: border-box;
      padding-top: 110px;
      margin: 0 auto;
      .lunbotu {
        width: 600px;
        height: 380px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .icon {
          &:hover {
            cursor: pointer;
          }
        }
        .quan {
          width: 460px;
          //轮播图指示器样式
          /deep/ .el-carousel__indicators--horizontal {
            li {
              padding: 20px 12px 0;
            }
            button {
              width: 12px;
              height: 12px;
              border-radius: 50% !important;
              background: #d0d4e2;
            }
          }
          /deep/ .is-active button {
            background: #0e60ff;
          }
        }
      }
      .info {
        width: 550px;
        height: 316px;
        margin-left: 50px;
        .text1 {
          width: 550px;
          height: 116px;
          font-size: 24px;
          font-family: Source Han Sans SC;
          font-weight: 400;
          line-height: 40px;
          color: #626778;
          margin-top: 20px;
        }
        .text2 {
          width: 550px;
          height: 116px;
          font-size: 24px;
          font-family: Source Han Sans SC;
          font-weight: 400;
          line-height: 40px;
          color: #626778;
          margin-top: 24px;
        }
      }
    }
  }
  .advantage {
    height: 734px;
    background: #f4f5f9;
    box-sizing: border-box;
    padding-top: 80px;
    .advantagetitle {
      width: 310px;
      height: 54px;
      font-size: 36px;
      font-family: Source Han Sans SC;
      font-weight: bold;
      line-height: 54px;
      color: #2d394b;
      text-align: center;
      margin: 0 auto;
    }
    .advantageinfo {
      width: 1200px;
      height: 540px;
      display: flex;
      margin: 60px auto 0;
      .info1 {
        width: 340px;
        height: 540px;
        .image1,
        .image2 {
          width: 80px;
          height: 80px;
        }
        .image2 {
          margin-top: 60px;
        }
        .tal {
          width: 40px;
          height: 29px;
          font-size: 20px;
          font-family: Source Han Sans SC;
          font-weight: bold;
          line-height: 29px;
          color: #2d394b;
          margin-top: 20px;
        }
        .text {
          width: 330px;
          height: 62px;
          font-size: 14px;
          font-family: Source Han Sans SC;
          font-weight: 400;
          line-height: 21px;
          color: #626778;
          margin-top: 9px;
        }
      }
      .info2 {
        margin-left: 84px;
        margin-top: 20px;
        width: 348px;
        height: 520px;
        img {
          width: 348px;
          height: 520px;
        }
      }
      .info3 {
        width: 340px;
        height: 540px;
        margin-left: 96px;
        .image1,
        .image2 {
          width: 80px;
          height: 80px;
        }
        .image2 {
          margin-top: 60px;
        }
        .tal {
          width: 40px;
          height: 29px;
          font-size: 20px;
          font-family: Source Han Sans SC;
          font-weight: bold;
          line-height: 29px;
          color: #2d394b;
          margin-top: 20px;
        }
        .text {
          width: 330px;
          height: 62px;
          font-size: 14px;
          font-family: Source Han Sans SC;
          font-weight: 400;
          line-height: 21px;
          color: #626778;
          margin-top: 9px;
        }
      }
    }
  }
  .platform {
    height: 1774px;
    background: #ffffff;
    box-sizing: border-box;
    padding-top: 80px;
    .platformtitle {
      width: 310px;
      height: 54px;
      font-size: 36px;
      font-family: Source Han Sans SC;
      font-weight: bold;
      line-height: 54px;
      color: #2d394b;
      text-align: center;
      margin: 0 auto;
    }
    .platforminfo {
      width: 1200px;
      margin: 80px auto;
      .tel,
      .tel3 {
        display: flex;
        .info {
          position: relative;
          width: 550px;
          margin-left: 150px;
          margin-top: 85px;
          z-index: 99;
          &::before {
            position: absolute;
            left: -11px;
            top: -6px;
            content: "";
            width: 48px;
            height: 48px;
            background: linear-gradient(133deg, #b9cdfb 0%, #f3f7ff 100%);
            border-radius: 50%;
            z-index: -1;
          }
          .title {
            width: 267px;
            height: 54px;
            font-size: 36px;
            font-family: Source Han Sans SC;
            font-weight: 500;
            line-height: 54px;
            color: #2d394b;
          }
          .text {
            width: 550px;
            height: 156px;
            font-size: 24px;
            font-family: Source Han Sans SC;
            font-weight: 400;
            line-height: 40px;
            color: #626778;
            margin-top: 20px;
          }
        }
      }
      .tel2 {
        display: flex;
        margin-top: 80px;
        .info {
          position: relative;
          width: 550px;
          margin-top: 80px;
          z-index: 99;
          &::before {
            position: absolute;
            left: -11px;
            top: -6px;
            content: "";
            width: 48px;
            height: 48px;
            background: linear-gradient(133deg, #b9cdfb 0%, #f3f7ff 100%);
            border-radius: 50%;
            z-index: -1;
          }
          .title {
            width: 267px;
            height: 54px;
            font-size: 36px;
            font-family: Source Han Sans SC;
            font-weight: 500;
            line-height: 54px;
            color: #2d394b;
          }
          .text {
            width: 550px;
            height: 156px;
            font-size: 24px;
            font-family: Source Han Sans SC;
            font-weight: 400;
            line-height: 40px;
            color: #626778;
            margin-top: 20px;
          }
        }
        img {
          margin-left: 150px;
        }
      }
      .tel3 {
        margin-top: 80px;
        .info {
          margin-top: 80px;
        }
      }
    }
  }
}
</style>
