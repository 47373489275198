<template>
  <div class="home">
    <el-container>
      <!-- 页头 -->
      <el-header>
        <div class="title">
          <div class="image" @click="shouye">
            <img src="../assets/img/title.png" />
          </div>
          <div class="titleBtn">
            <div @click="shouye" class="active">首页</div>
            <div @click="product">产品介绍</div>
            <div @click="feature">功能介绍</div>
            <div @click="download">下载</div>
          </div>
          <div class="denglu" @click="logins">登录网吧后台</div>
        </div>
      </el-header>
      <!-- 主体内容 -->
      <el-main>
        <div class="homemain"></div>
        <div class="maininfo">
          <div class="info">
            <div class="texin">新版特性</div>
            <div class="comman">
              <div class="back">
                <img src="../assets/img/info1.png" />
              </div>
              <div class="commaninfo">
                <div class="text1">8圈门禁</div>
                <div class="text2"></div>
                <div class="text3">
                  针对电竞馆、电竞酒店、民宿、酒店、茶楼、咖啡厅等经营场所，通过智能硬件设备接入，采用实名解锁，为场所提供安全保障的一款智能场所门禁管理系统。
                </div>
                <div class="text4">
                  <div class="text4title">
                    <img src="../assets/img/icon1.png" />
                    <div>包间消费模式自由选择</div>
                  </div>
                  <div class="text4div">
                    单人模式只需支付自己的上网费用，全包模式一人付整个包间费用
                  </div>
                  <div class="text4title">
                    <img src="../assets/img/icon2.png" />
                    <div>包间门禁与包间消费模式关联</div>
                  </div>
                  <div class="text4div">
                    包间被占用时无法解锁，有效保护包间内设备、私人物品安全，降低经营中的安全风险
                  </div>
                  <div class="text4title">
                    <img src="../assets/img/icon3.png" />
                    <div>多种方式解锁记录实时采集</div>
                  </div>
                  <div class="text4div">
                    支持蓝牙、门禁卡、去上网、掌上8圈实名解锁，降低包间人员身份核验难度
                  </div>
                </div>
              </div>
            </div>
            <div class="comman2">
              <div class="comman2info">
                <div class="text1">8圈鹰眼</div>
                <div class="text2"></div>
                <div class="text3">
                  应用AI技术对场所内人员自动识别，能快速进行会员身份信息认证的智能场所会员识别系统。
                </div>
                <div class="text4">
                  <img src="../assets/img/icon4.png" />
                  <div>对场所内会员进行自动识别认证，提供专属上网服务</div>
                </div>
                <div class="text5">
                  <img src="../assets/img/icon5.png" />
                  <div>与去上网APP实时联动，实现快速激活上网</div>
                </div>
                <div class="btn" @click="goeagleEye">
                  <div>查看详情</div>
                  <img src="../assets/img/right.png" />
                </div>
              </div>
              <div class="back">
                <img src="../assets/img/info2.png" />
              </div>
            </div>
            <div class="comman3">
              <div class="back">
                <img src="../assets/img/info3.png" />
              </div>
              <div class="comman3info">
                <div class="text1">8圈机器人</div>
                <div class="text2"></div>
                <div class="text3">
                  集AI人脸识别、无人收银系统、网民自助上网、智能财务管理等功能于一体的智能自助服务设备。
                </div>
                <div class="text4">
                  <img src="../assets/img/icon6.png" />
                  <div>自助上机、充值、办理会员、购买包时、结账下机功能</div>
                </div>
                <div class="text5">
                  <img src="../assets/img/icon7.png" />
                  <div>全天工作，节省人力成本开支</div>
                </div>
                <div class="text5">
                  <img src="../assets/img/icon8.png" />
                  <div>数据统一云端存储，信息管理更安全</div>
                </div>
                <div class="btn" @click="gorobot">
                  <div>查看详情</div>
                  <img src="../assets/img/right.png" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </el-main>
      <!-- 底部 -->
      <footerComponents></footerComponents>
    </el-container>
  </div>
</template>

<script>
import footerComponents from "../components/footer.vue";
export default {
  name: "Home",
  components: { footerComponents },
  data() {
    return {};
  },
  mounted() {
    if (
      /Android|webOS|iPhone|iPod|BlackBerry|Harmony/i.test(navigator.userAgent)
    ) {
      //移动设备打开的网页，缩放页面尺寸
      /*
  		var clientWidth = document.body.clientWidth;
  		var rate = clientWidth / 1920;
  		*/
      var rate = 0.1;
      var meta = document.getElementsByTagName("meta");
      meta["viewport"].setAttribute(
        "content",
        "width=device-width,initial-scale=" + rate
      );
    }
  },
  methods: {
    //跳转首页
    shouye() {
      this.$router.push("/");
    },
    //点击转跳产品介绍
    product() {
      this.$router.push("/product");
    },
    //跳转功能介绍页面
    feature() {
      this.$router.push("/feature");
    },
    //跳转下载页面
    download() {
      this.$router.push("/download");
    },
    //跳转8全机器人
    gorobot() {
      // this.$router.push("/robot0");
      //在新页面打开
      let routeUrl = this.$router.resolve("/robot0");
      window.open(routeUrl.href, "_blank");
    },
    //跳转8圈鹰眼
    goeagleEye() {
      // this.$router.push("/eagleEye");
      //在新页面打开
      let routeUrl = this.$router.resolve("/eagleEye0");
      window.open(routeUrl.href, "_blank");
    },
    //登录网吧后台
    logins() {
      window.open("http://b.8quan.com");
    },
  },
};
</script>
<style lang="less" scoped>
// 页头样式
.el-header {
  width: 100%;
  min-width: 1320px;
  margin: 0 auto;
  padding: 0;
  height: 80px !important;
  line-height: 80px;
  .title {
    display: flex;
    align-items: center;
    width: 1200px;
    margin: 0 auto;
    .image {
      min-width: 286px;
      height: 48px;
      margin-right: 2px;
      img {
        width: 286px;
        height: 48px;
      }
      &:hover {
        cursor: pointer;
      }
    }
    .titleBtn {
      display: flex;
      align-items: center;
      height: 80px;
      box-sizing: border-box;
      > div {
        margin-left: 80px;
        min-width: 36px;
        height: 27px;
        font-size: 18px;
        font-family: Source Han Sans SC;
        font-weight: 500;
        line-height: 27px;
        color: #666666;
        text-align: center;
      }
      .active {
        position: relative;
        color: #3586f1;
      }
      .active::after {
        position: absolute;
        bottom: -10px;
        content: "";
        width: 30px;
        height: 4px;
        background: #3586f1;
        left: 50%;
        transform: translate(-50%);
      }
      :hover {
        cursor: pointer;
      }
    }
    .denglu {
      width: 120px;
      height: 40px;
      background: #3586f1;
      border-radius: 8px;
      font-size: 14px;
      font-family: Source Han Sans SC;
      font-weight: 500;
      line-height: 40px;
      color: #ffffff;
      text-align: center;
      margin-left: 256px;
      &:hover {
        cursor: pointer;
      }
    }
  }
}

.el-main {
  width: 100%;
  min-width: 1320px;
  padding: 0;
  margin: 0 auto;
  .homemain {
    margin: 0 auto;
    height: 460px;
    background-image: url(../assets/img/productback.png);
    background-size: cover;
    /* 将背景图片等比缩放填满整个容器 */
    background-repeat: no-repeat;
    background-position: center;
  }
  .maininfo {
    background: #f4f5f9;
    .info {
      width: 1200px;
      margin: 0 auto;
      padding: 60px 0 80px;
      .texin {
        width: 310px;
        height: 54px;
        font-size: 36px;
        font-family: Source Han Sans SC-Bold, Source Han Sans SC;
        font-weight: bold;
        color: #161e2a;
        margin-bottom: 30px;
      }
      // 8圈门禁
      .comman {
        display: flex;
        padding: 40px;
        width: 1200px;
        background: #ffffff;
        border-radius: 12px;
        box-sizing: border-box;
        .back {
          width: 600px;
          height: 400px;
          img {
            width: 600px;
            height: 400px;
          }
        }
        .commaninfo {
          width: 490px;
          margin-left: 30px;
          .text1 {
            width: 130px;
            height: 41px;
            font-size: 28px;
            font-family: Source Han Sans SC;
            font-weight: bold;
            line-height: 41px;
            color: #161e2a;
          }
          .text2 {
            width: 32px;
            height: 4px;
            background: #3586f1;
            margin-top: 8px;
          }
          .text3 {
            width: 490px;
            height: 72px;
            font-size: 16px;
            font-family: Source Han Sans SC;
            font-weight: 400;
            line-height: 24px;
            color: #626778;
            margin-top: 16px;
          }
          .text4 {
            margin-top: 40px;
            .text4title {
              display: flex;
              margin-top: 30px;
              img {
                width: 24px;
                height: 24px;
              }
              > div {
                width: 310px;
                height: 24px;
                font-size: 16px;
                font-family: Source Han Sans SC;
                font-weight: 500;
                line-height: 24px;
                color: #161e2a;
                margin-left: 8px;
              }
            }
            .text4div {
              width: 490px;
              font-size: 14px;
              font-family: Source Han Sans SC;
              font-weight: 400;
              line-height: 20px;
              color: #818697;
              margin-top: 8px;
            }
          }
        }
      }
      //8圈鹰眼
      .comman2 {
        display: flex;
        margin-top: 30px;
        width: 1200px;
        background: #ffffff;
        border-radius: 12px;
        box-sizing: border-box;
        padding: 40px;
        .comman2info {
          width: 490px;
          .text1 {
            width: 130px;
            height: 41px;
            font-size: 28px;
            font-family: Source Han Sans SC;
            font-weight: bold;
            line-height: 41px;
            color: #161e2a;
          }
          .text2 {
            width: 32px;
            height: 4px;
            background: #3586f1;
            margin-top: 8px;
          }
          .text3 {
            width: 490px;
            height: 48px;
            font-size: 16px;
            font-family: Source Han Sans SC;
            font-weight: 400;
            line-height: 24px;
            color: #626778;
            margin-top: 16px;
          }
          .text4,
          .text5 {
            display: flex;
            margin-top: 30px;
            img {
              width: 24px;
              height: 24px;
            }
            > div {
              margin-left: 8px;
              height: 24px;
              font-size: 16px;
              font-family: Source Han Sans SC;
              font-weight: 500;
              line-height: 24px;
              color: #161e2a;
            }
          }
          .text4 {
            margin-top: 40px;
          }
          .btn {
            width: 148px;
            height: 48px;
            background: #3586f1;
            border-radius: 24px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: 60px;
            &:hover {
              cursor: pointer;
            }
            > div {
              width: 64px;
              height: 24px;
              font-size: 16px;
              font-family: Source Han Sans SC;
              font-weight: 400;
              line-height: 24px;
              color: #ffffff;
            }
            img {
              width: 16px;
              height: 16px;
              margin-left: 12px;
            }
          }
        }
        .back {
          width: 600px;
          height: 400px;
          margin-left: 30px;
          img {
            width: 600px;
            height: 400px;
          }
        }
      }
      //8圈机器人
      .comman3 {
        display: flex;
        margin-top: 30px;
        width: 1200px;
        background: #ffffff;
        border-radius: 12px;
        box-sizing: border-box;
        padding: 40px;
        .back {
          width: 600px;
          height: 400px;
          img {
            width: 600px;
            height: 400px;
          }
        }
        .comman3info {
          width: 490px;
          margin-left: 30px;
          .text1 {
            width: 130px;
            height: 41px;
            font-size: 28px;
            font-family: Source Han Sans SC;
            font-weight: bold;
            line-height: 41px;
            color: #161e2a;
          }
          .text2 {
            width: 32px;
            height: 4px;
            background: #3586f1;
            margin-top: 8px;
          }
          .text3 {
            width: 490px;
            height: 48px;
            font-size: 16px;
            font-family: Source Han Sans SC;
            font-weight: 400;
            line-height: 24px;
            color: #626778;
            margin-top: 16px;
          }
          .text4,
          .text5 {
            display: flex;
            margin-top: 30px;
            img {
              width: 24px;
              height: 24px;
            }
            > div {
              margin-left: 8px;
              height: 24px;
              font-size: 16px;
              font-family: Source Han Sans SC;
              font-weight: 500;
              line-height: 24px;
              color: #161e2a;
            }
          }
          .text4 {
            margin-top: 40px;
          }
          .btn {
            width: 148px;
            height: 48px;
            background: #3586f1;
            border-radius: 24px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: 60px;
            &:hover {
              cursor: pointer;
            }
            > div {
              width: 64px;
              height: 24px;
              font-size: 16px;
              font-family: Source Han Sans SC;
              font-weight: 400;
              line-height: 24px;
              color: #ffffff;
            }
            img {
              width: 16px;
              height: 16px;
              margin-left: 12px;
            }
          }
        }
      }
    }
  }
}
</style>
